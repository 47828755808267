import { Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import axios from 'axios';

import FontAwesome from '@component/shared/font-awesome';
import useTheme from '@util/hooks/useTheme';
import component from '@config/component';
import useData from '@data/useData';

const FooterUnStyled = ({ className }) => {
	const [formData, setFormData] = useState({ contact: '' });
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [message, setMessage] = useState('');

	const navigate = useNavigate();
	const { showLoader } = useTheme();
	const { getSocials } = useData();
	const socials = getSocials();

	const handleLink = (e) => {
		e.preventDefault();
		const url = new URL(e.target.href);
		showLoader({ navigate: () => navigate(url.pathname + url.search) });
	};

	useEffect(() => {
		setTimeout(() => {
			if (message) setMessage('');
		}, 5000);
	}, [message]);

	const submitForm = (e) => {
		e.preventDefault();
		setLoading(true);
		setErrors({});
		axios
			.post(component.makeApiEndpoint('contact/request'), formData)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					setMessage(data.message);
					setFormData({ contact: '' });
				}
			})
			.catch((err) => {
				const { data } = err.response;
				if (data.hasOwnProperty('errors') && data.errors.length > 0)
					data.errors.forEach((error) => setErrors({ ...errors, [error.path]: error.message }));
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={className}>
			<Container>
				<div className="footer-top">
					<Row>
						<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
							<div className="content">
								<img
									loading={'lazy'}
									src="/assets/images/logo/logo-1.svg"
									alt="logo"
									className={'logo'}
								/>
								<p>
									<FontAwesome icon={'location-dot'} className={'me-2'} />
									Block-B Road 18, Plot 29, Banani, Dhaka-1213
								</p>
								<p>
									<FontAwesome icon={'mobile-notch'} className={'me-1'} /> (+880) 155 000 6606, (+880)
									155 000 6564
								</p>
								<p>
									<FontAwesome icon={'at'} className={''} /> hello@domicile.com.bd
								</p>
							</div>
						</Col>
						<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
							<div className="form">
								<label htmlFor="">Request a call</label>
								<form onSubmit={submitForm}>
									<div className="field">
										<input
											type="tel"
											placeholder={'Enter Your Number'}
											value={formData.contact}
											onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
										/>
										<button type={'submit'} disabled={loading}>
											{loading && (
												<FontAwesome icon={'spinner'} animation={'spin'} className={'me-2'} />
											)}
											Submit
										</button>
									</div>
									{errors.hasOwnProperty('contact') && (
										<div className="field-error">{errors.contact}</div>
									)}
									{message && <div className="field-success">{message}</div>}
								</form>
							</div>
						</Col>
					</Row>
				</div>
				<div className="footer-bottom">
					<div className="footer-bottom-section">
						<div className="icons">
							<a href={socials.facebook} target={'_blank'} rel={'noreferrer'}>
								<FontAwesome type={'brands'} icon={'facebook'} />
							</a>
							<a href={socials.instagram} target={'_blank'} rel={'noreferrer'}>
								<FontAwesome type={'brands'} icon={'instagram'} />
							</a>
							<a href={socials.linkedin} target={'_blank'} rel={'noreferrer'}>
								<FontAwesome type={'brands'} icon={'linkedin'} />
							</a>
						</div>

						<ul className="links">
							<li>
								<Link to={'/'} onClick={handleLink}>
									Home
								</Link>
							</li>
							<li>
								<Link to={'/projects'} onClick={handleLink}>
									All projects
								</Link>
							</li>
							<li>
								<Link to={'/contact'} onClick={handleLink}>
									Contact
								</Link>
							</li>
						</ul>
					</div>
					<hr />
					<div className="footer-bottom-section">
						<p className={'copyright'}>© {new Date().getFullYear()} Copyright DDB Limited</p>

						<ul className="links">
							<li>
								<Link to={'/privacy-policy'} onClick={handleLink}>
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link to={'/terms-conditions'} onClick={handleLink}>
									Terms & Conditions
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</Container>
		</div>
	);
};

const Footer = styled(FooterUnStyled)`
	background: ${component.color.primary};
	padding: 80px 0;

	.footer {
		&-top {
			margin-bottom: 60px;

			.row {
				align-items: center;
			}

			.logo {
				width: 180px;
				margin-bottom: 40px;
			}

			p {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[200]};
				color: ${component.color.white};
				font-size: 14px;
				margin-bottom: 10px;
			}

			.content {
				${() => component.breakpoint.sm.mixin('text-align:center;')}
				${() => component.breakpoint.xs.mixin('text-align:center;')}
                ${() => component.breakpoint.xxs.mixin('text-align:center;')}
			}

			.form {
				justify-self: end;
				min-width: 400px;

				${() => component.breakpoint.md.mixin('min-width: 300px;')}
				${() => component.breakpoint.sm.mixin('display: none; visibility: hidden;')}
                ${() => component.breakpoint.xs.mixin('display: none; visibility: hidden;')}
                ${() => component.breakpoint.xxs.mixin('display: none; visibility: hidden;')}
                label {
					font-family: ${component.font.family.reckless};
					font-weight: ${component.font.weight[400]};
					font-size: 18px;
					display: block;
					color: ${component.color.white};
					margin-bottom: 10px;
				}

				.field {
					position: relative;
					display: flex;
					align-items: center;

					input {
						display: block;
						width: 100%;
						height: 50px;
						background: none;
						border: none;
						border-bottom: 1px solid ${hexToRgba(component.color.white, 0.5)};
						right: 0;
						font-family: ${component.font.family.poppins};
						font-weight: ${component.font.weight[200]};
						font-size: 14px;
						color: ${component.color.white};

						&:hover,
						&:focus,
						&:active {
							outline: none;
							box-shadow: none;
						}

						&::placeholder {
							font-family: ${component.font.family.poppins};
							font-weight: ${component.font.weight[200]};
							font-size: 14px;
							color: ${hexToRgba(component.color.white, 0.5)};
						}
					}

					button {
						position: absolute;
						background: none;
						border: 1px solid ${hexToRgba(component.color.white, 0.5)};
						color: ${component.color.white};
						border-radius: 20px;
						padding: 2px 20px;
						text-transform: uppercase;
						right: 0;
						font-size: 14px;
					}

					&-error {
						font-family: ${component.font.family.poppins};
						font-weight: ${component.font.weight[300]};
						color: #f60b0b;
						font-size: 14px;
						margin-top: 10px;
					}

					&-success {
						font-family: ${component.font.family.poppins};
						font-weight: ${component.font.weight[300]};
						color: #42dd00;
						font-size: 14px;
						margin-top: 10px;
					}
				}
			}
		}

		&-bottom {
			hr {
				border: 1px solid ${hexToRgba(component.color.white, 0.5)};
			}

			&-section {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-top: 40px;

				&:first-child {
					${() => component.breakpoint.xs.mixin('flex-direction: column;')}
					${() => component.breakpoint.xs.mixin('flex-direction: column;')}
                    ${() => component.breakpoint.xxs.mixin('flex-direction: column;')}
				}

				${() => component.breakpoint.xs.mixin('flex-direction: column-reverse;')}
				${() => component.breakpoint.xxs.mixin('flex-direction: column-reverse;')}
                &:last-child {
					padding-top: 0;

					.copyright {
						font-family: ${component.font.family.poppins};
						font-weight: ${component.font.weight[200]};
						font-size: 14px;
						color: ${component.color.white};
						margin-bottom: 0;
					}

					.links {
						padding-left: 0;
						display: inline-flex;
						text-align: right;
						margin-bottom: 0;

						li {
							list-style: none;
							margin-left: 20px;

							${() => component.breakpoint.xs.mixin('margin-left: 10px; margin-bottom: 10px;')}
							${() => component.breakpoint.xxs.mixin('margin-left: 10px; margin-bottom: 10px;')}
                            a {
								font-family: ${component.font.family.poppins};
								font-weight: ${component.font.weight[200]};
								color: ${component.color.white};
								text-decoration: none;
								font-size: 14px;
							}
						}
					}
				}

				.icons {
					${() => component.breakpoint.xs.mixin('margin-bottom: 10px;')}
					${() => component.breakpoint.xxs.mixin('margin-bottom: 10px;')}
                    i {
						font-size: 16px;
						color: ${component.color.white};
						margin-right: 10px;
					}
				}

				.links {
					padding-left: 0;
					display: inline-flex;
					text-align: right;
					margin-bottom: 0;

					li {
						list-style: none;
						margin-left: 20px;

						${() => component.breakpoint.xs.mixin('margin-left: 15px;')}
						${() => component.breakpoint.xxs.mixin('margin-left: 15px;')}
                        a {
							font-family: ${component.font.family.poppins};
							font-weight: ${component.font.weight[200]};
							color: ${component.color.white};
							text-decoration: none;
							font-size: 14px;
						}
					}
				}
			}
		}
	}
`;

export default Footer;
