import { useDispatch, useSelector } from 'react-redux';

import {
	openMenu as OM,
	closeMenu as CM,
	openJob as OJ,
	closeJob as CJ,
	toggleMenu as TM,
	showPreloader as SP,
	hidePreloader as HP,
	showLoader as SL,
	hideLoader as HL
} from '@store/reducers/theme';

const useTheme = () => {
	const dispatch = useDispatch();
	const { isPreloaderEnabled, isLoaderEnabled, isMenuOpen, isJobOpen, options } = useSelector((state) => state.theme);

	const openMenu = () => dispatch(OM());
	const closeMenu = () => dispatch(CM());
	const toggleMenu = () => dispatch(TM());

	const openJob = (options) => dispatch(OJ(options));
	const closeJob = () => dispatch(CJ());

	const showPreloader = () => dispatch(SP());
	const hidePreloader = () => dispatch(HP());

	const showLoader = (options) => {
		dispatch(SL(options));
	};
	const hideLoader = () => dispatch(HL());

	return {
		openMenu,
		closeMenu,
		toggleMenu,
		openJob,
		closeJob,
		showPreloader,
		hidePreloader,
		showLoader,
		hideLoader,
		isPreloaderEnabled,
		isLoaderEnabled,
		isMenuOpen,
		isJobOpen,
		options
	};
};

export default useTheme;
