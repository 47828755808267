import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import theme from '@store/reducers/theme';

const persistConfig = (key, blacklist = []) => {
	return {
		key,
		blacklist,
		storage
	};
};

const reducers = combineReducers({
	theme: persistReducer(persistConfig('theme'), theme)
});

export default reducers;
