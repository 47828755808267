import styled from 'styled-components';
import classNames from 'classnames';

import component from '@config/component';

const TitleUnStyled = ({ className, text, api }) => {
	const classes = classNames(className, 'hero-title');
	return <h1 className={classes}>{text}</h1>;
};

const Title = styled(TitleUnStyled)`
	width: 700px;

	${() => component.breakpoint.md.mixin('text-align: center; width: 100%;')}
	${() => component.breakpoint.sm.mixin('text-align: center; width: 100%;')}
    ${() => component.breakpoint.xs.mixin('text-align: center; width: 100%;')}
    ${() => component.breakpoint.xxs.mixin('text-align: center; width: 100%;')}
	
	font-family: ${component.font.family.reckless};
	font-weight: ${component.font.weight[400]};
	font-size: 80px;
	color: ${component.color.white};
	margin-top: 80px;

	${() => component.breakpoint.md.mixin('font-size: 60px;')}
	${() => component.breakpoint.sm.mixin('font-size: 50px;')}
    ${() => component.breakpoint.xs.mixin('font-size: 40px;')}
    ${() => component.breakpoint.xxs.mixin('font-size: 40px;')}
`;

export default Title;
