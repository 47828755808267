import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import reducers from '@store/reducers';

export const store = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});

export const persist = persistStore(store);
