import styled from 'styled-components';

import useTheme from '@util/hooks/useTheme';
import component from '@config/component';

import { useGSAP } from '@gsap/react';
import { gsap } from 'gsap';

const LoaderUnStyled = ({ className }) => {
	const { hideLoader, isLoaderEnabled, options } = useTheme();

	useGSAP(() => {
		const timeline = gsap.timeline({});
		if (isLoaderEnabled) {
			timeline
				.fromTo(
					'.comp-alt',
					{
						height: '0'
					},
					{
						height: '100vh',
						duration: 1,
						ease: 'power1.inOut'
					}
				)
				.fromTo(
					'.comp-main',
					{
						height: '0'
					},
					{
						height: '100vh',
						delay: -1,
						duration: 1,
						ease: 'power1.inOut'
					}
				)
				.fromTo(
					'.logo',
					{
						opacity: 0,
						y: -50
					},
					{
						opacity: 1,
						y: 0,
						duration: 1,
						ease: 'expo.inOut'
					}
				)
				.add(() => {
					if (options.hasOwnProperty('navigate') && typeof options.navigate === 'function')
						options.navigate();
					if (options.hasOwnProperty('callback') && typeof options.callback === 'function')
						options.callback();
				})
				.to('.logo', {
					opacity: 0,
					y: -50,
					duration: 1,
					ease: 'expo.inOut'
				})
				.to('.comp-main', {
					height: '0',
					y: -10,
					duration: 1,
					ease: 'power1.inOut'
				})
				.to('.comp-alt', {
					height: '0',
					y: -10,
					delay: -1,
					duration: 1,
					ease: 'power1.inOut',
					onComplete: hideLoader
				});
		}
	}, [isLoaderEnabled]);

	return (
		<div className={className}>
			<div className={'comp-main'}>
				<img className={'logo'} src="/assets/images/logo/logo-1.svg" alt="Domicile Logo" width={200} />
			</div>
			<div className={'comp-alt'} />
		</div>
	);
};

const Loader = styled(LoaderUnStyled)`
	top: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1001;
	overflow: hidden;

	.comp {
		&-main {
			height: 100vh;
			width: 100vw;
			background: ${component.color.primary};
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		&-alt {
			height: 100vh;
			width: 100vw;
			background: ${component.color.white};
		}
	}
`;

export default Loader;
