import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import Breadcrumb from '@component/layout/main/breadcrumb';

import PreFooter from '@component/shared/prefooter';
import component from '@config/component';

import useData from '@data/useData';

const PrivacyUnStyled = ({ className }) => {
	const { getPage } = useData();
	const { breadcrumb } = getPage('privacy');

	return (
		<div className={className}>
			<Breadcrumb title={breadcrumb.title} background={breadcrumb.background} />
			<Container className={'py-5'}>
				<h2 className={'mb-4'}>Effective Date: [Insert Date]</h2>
				<p>
					Domicile ("we," "our," or "us") operates the website www.domicile.com.bd (the "Site"). This page
					informs you of our policies regarding the collection, use, and disclosure of Personal Information we
					receive from users of the Site.
				</p>

				<h2 className={'mt-5 mb-4'}>1. Information Collection and Use</h2>
				<p>
					While using our Site, we may ask you to provide us with certain personally identifiable information
					that can be used to contact or identify you. Personally identifiable information may include, but is
					not limited to, your name, email address, and phone number ("Personal Information").
				</p>
				<p>
					When you visit the Site, we automatically collect certain information about your device, including
					information about your web browser, IP address, time zone, and some of the cookies that are
					installed on your device. Additionally, as you browse the Site, we collect information about the
					individual web pages or products that you view, what websites or search terms referred you to the
					Site, and information about how you interact with the Site. We refer to this automatically-collected
					information as "Device Information."
				</p>

				<p>We collect Device Information using the following technologies:</p>
				<ul>
					<li>
						Cookies: Data files that are placed on your device or computer and often include an anonymous
						unique identifier. For more information about cookies, and how to disable cookies, visit
						www.domicile.com.bd.
					</li>
					<li>
						Log files: Track actions occurring on the Site, and collect data including your IP address,
						browser type, Internet service provider, referring/exit pages, and date/time stamps.
					</li>
					<li>
						Web beacons, tags, and pixels: Electronic files used to record information about how you browse
						the Site.
					</li>
				</ul>

				<h2 className={'mt-5 mb-4'}>2. Storing Your Personal Information & Retention Period</h2>
				<p>
					If you are a European resident, we note that we are processing your information in order to fulfill
					contracts we might have with you (for example, if you make an order through the Site), or otherwise
					to pursue our legitimate business interests listed above. Additionally, please note that your
					information will be transferred outside of Europe, including to Canada and the United States.
				</p>
				<p>
					You have the following rights in accordance with the Data Protection Act 1998, General Data
					Protection Regulation (GDPR), and Data Protection Bill:
				</p>
				<ul>
					<li>
						The right to be informed. In this privacy statement, we supply you with detailed information
						related to your personal data storage, maintenance, and processing.
					</li>
					<li>The right to access information held about you.</li>
					<li>The right to rectify your personal data if it is inaccurate, incomplete, or out-of-date.</li>
					<li>The right to erasure (delete or remove) your personal data.</li>
					<li>
						The right to restrict (block) processing. When processing is restricted, we will store your
						personal data, but not further process it.
					</li>
					<li>
						The right to data portability, which allows individuals to obtain and reuse their personal data
						for their own purposes across different services. It allows them to move, copy, or transfer
						personal data easily from one IT environment to another in a safe and secure way, without
						hindrance to usability.
					</li>
					<li>
						Rights related to automated decision-making, including profiling. We are not making any
						decisions which might produce a legal effect on you based on automated processing.
					</li>
					<li>
						The right to withdraw your consent (object) to direct marketing. You can exercise your rights by
						contacting us at hello@domicile.com.bd.
					</li>
					<li>
						The right to lodge a complaint with a supervisory authority. You have the right to lodge a
						complaint with a supervisory authority (Information Commissioner's Office in the United Kingdom)
						if you have concerns about our information rights practices.
					</li>
				</ul>

				<h2 className={'mt-5 mb-4'}>3. Data retention</h2>
				<p>
					When you subscribe or place an order through the Site, we will maintain your Information for our
					records unless and until you ask us to delete this information. We retain your Personal Information
					only for as long as necessary to fulfill the purposes for which it was collected, including for the
					purposes of satisfying any legal, accounting, or reporting requirements. To determine the
					appropriate retention period for Personal Information, we consider the amount, nature, and
					sensitivity of the Personal Information, the potential risk of harm from unauthorized use or
					disclosure of your Personal Information, the purposes for which we process your Personal
					Information, and whether we can achieve those purposes through other means, and the applicable legal
					requirements.
				</p>

				<h2 className={'mt-5 mb-4'}>4. Security</h2>
				<p>
					The security of your Personal Information is important to us, but remember that no method of
					transmission over the Internet, or method of electronic storage, is 100% secure. We implement a
					variety of security measures to maintain the safety of your Personal Information when you enter,
					submit, or access your Personal Information. These measures include, but are not limited to,
					encryption, firewalls, and secure socket layer (SSL) technology. While we strive to use commercially
					acceptable means to protect your Personal Information, we cannot guarantee its absolute security. In
					the event of a data breach, we will notify you and the relevant authorities as required by law.
				</p>

				<h2 className={'mt-5 mb-4'}>5. Changes to This Privacy Policy</h2>
				<p>
					Domicile may update this Privacy Policy from time to time to reflect changes to our practices or for
					other operational, legal, or regulatory reasons. We will notify you of any changes by posting the
					new Privacy Policy on the Site and updating the "Effective Date" at the top of this page. You are
					advised to review this Privacy Policy periodically for any changes. Your continued use of the Site
					after any modifications to the Privacy Policy will constitute your acknowledgment of the
					modifications and your consent to abide and be bound by the modified Privacy Policy.
				</p>

				<h2 className={'mt-5 mb-4'}>6. Contact Us</h2>
				<p>
					If you have any questions about this Privacy Policy, please contact us at: hello@domicile.com.bd.
					You can also reach us by mail at:
				</p>
				<p>Domicile [Insert Address] Dhaka, Bangladesh</p>
				<p>
					We are committed to resolving any complaints about our collection or use of your Personal
					Information. If you have any concerns, please contact us first, and we will do our best to address
					them promptly.
				</p>
			</Container>

			<PreFooter
				bg={component.image('faca88127765023.6148cad26a35f_ejraon', [
					'f_webp',
					'q_auto',
					'w_1920',
					'h_600',
					'c_fill'
				])}
				content={'Creating Exceptional Spaces\nYour Perfect Home Starts with Domicile'}
			/>
		</div>
	);
};

const Privacy = styled(PrivacyUnStyled)`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${component.font.family.reckless};
	}

	h1 {
		font-size: 42px;
	}

	& h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 30px;
	}

	h4 {
		font-size: 24px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 12px;
	}

	p {
		font-family: ${component.font.family.poppins};
		font-weight: ${component.font.weight[300]};
		line-height: 26px;
		font-size: 14px;
		text-align: justify;
	}

	ul {
		li {
			font-family: ${component.font.family.poppins};
			font-weight: ${component.font.weight[300]};
			line-height: 26px;
			font-size: 14px;
			text-align: justify;
		}
	}

	img {
		object-fit: cover;
	}

	.section {
		margin-bottom: 40px;

		.title {
			font-family: ${component.font.family.reckless};
			font-size: 48px;
			margin-bottom: 20px;
		}

		.content {
			text-align: justify-all;

			p {
				text-align: justify;
			}
		}
	}
`;

export default Privacy;
