import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import component from '@config/component';

const BreadcrumbUnStyled = ({ className, title, subtitle = '', logo, background }) => {
	return (
		<div className={className}>
			<img
				src={component.image(background, ['f_webp', 'q_auto', 'w_1920', 'h_700', 'c_fill'])}
				alt={title}
				loading={'lazy'}
				height={700}
			/>
			<div className="overlay">
				<Container>
					{
						logo && (
							<img
								src={component.image(logo, ['f_webp', 'q_auto', 'w_250'])}
								alt={title}
								loading={'lazy'}
							/>
						)
					}
					<h1>{title}</h1>
					{subtitle && <h5>{subtitle}</h5>}
				</Container>
			</div>
		</div>
	);
};

const Breadcrumb = styled(BreadcrumbUnStyled)`
	width: 100%;
	height: 700px;
	position: relative;

	${() => component.breakpoint.xs.mixin('height: 400px;')}
	${() => component.breakpoint.xxs.mixin('height: 300px;')}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.overlay {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: ${hexToRgba(component.color.primary, 0.8)};
		z-index: 100;

		& .container {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: start;
			flex-direction: column;

			${() => component.breakpoint.xs.mixin('justify-content: end;')}
			${() => component.breakpoint.xxs.mixin('justify-content: end;')}
			
			img {
				width: 250px;
				height: 60px;
				object-fit: scale-down;
				object-position: left;
                -webkit-filter: drop-shadow(0 0 0 #ffffff);
                filter: drop-shadow(1px 1px 0 #ffffff);
				margin-bottom: 20px;
			}

			h1 {
				font-family: ${component.font.family.reckless};
				font-weight: ${component.font.weight[600]};
				font-size: 52px;
				color: ${component.color.white};

				${() => component.breakpoint.sm.mixin('font-size: 52px;')}
				${(props) =>
					component.breakpoint.xs.mixin(`font-size: 42px; ${!props.subtitle ? 'margin-bottom: 50px;' : ''}`)}
                ${(props) =>
					component.breakpoint.xxs.mixin(`font-size: 36px; ${!props.subtitle ? 'margin-bottom: 50px;' : ''}`)}
			}

			h5 {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[200]};
				font-size: 20px;
				color: ${component.color.white};

				${() => component.breakpoint.sm.mixin('font-size: 18px;')}
				${(props) =>
					component.breakpoint.xs.mixin(`font-size: 16px; ${props.subtitle ? 'margin-bottom: 50px;' : ''}`)}
                ${(props) =>
					component.breakpoint.xxs.mixin(`font-size: 14px; ${props.subtitle ? 'margin-bottom: 50px;' : ''}`)}
			}
		}
	}
`;

export default Breadcrumb;
