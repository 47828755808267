import component from '@config/component';
import hexToRgba from 'hex-to-rgba';

const select = {
	control: (base, state) => ({
		borderBottom: `2px solid ${hexToRgba(component.color.black, 0.2)}`,
		fontFamily: component.font.family.poppins,
		padding: '8px 0',
		fontSize: '16px',
		display: 'flex'
	}),
	placeholder: (base) => ({
		...base,
		color: hexToRgba(component.color.black, 0.4)
	}),
	valueContainer: (base) => ({
		...base,
		padding: 0
	}),
	indicatorSeparator: (base) => ({
		...base,
		display: 'none'
	}),
	clearIndicator: (base) => ({
		...base,
		paddingLeft: 0,
		paddingRight: 0,
		svg: {
			transform: 'scale(0.75)'
		},
		'&:hover': {
			cursor: 'pointer'
		}
	}),
	dropdownIndicator: (base) => ({
		...base,
		paddingLeft: 0,
		paddingRight: 0,
		svg: {
			transform: 'scale(0.75)'
		},
		'&:hover': {
			cursor: 'pointer'
		}
	})
};

export default select;
