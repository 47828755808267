import { animated, useSpring, useSpringRef, useChain, easings } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';

import FontAwesome from '@component/shared/font-awesome';

import useBreakpoint from '@util/hooks/useBreakpoint';

import component from '@config/component';

import useTheme from '@util/hooks/useTheme';
import useData from '@data/useData';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

const MenuUnStyled = ({ className }) => {
	const [immediate, setImmediate] = useState(false);
	const { isMenuOpen, toggleMenu, showLoader } = useTheme();
	const { isBelowOrEqual } = useBreakpoint();
	const { menu } = useData();

	const navigate = useNavigate();

	const wApi = useSpringRef();
	const wrapper = useSpring({
		ref: wApi,
		from: { x: `100%` },
		to: { x: isMenuOpen ? '0' : '100%' },
		delay: immediate ? 1000 : 0,
		config: { duration: immediate ? 0 : 1000, easing: easings.easeInOutQuart }
	});

	useChain([wApi], [isMenuOpen ? 0 : immediate ? 0 : 1]);

	useGSAP(() => {
		if (isMenuOpen) {
			gsap.fromTo(
				document.querySelectorAll('.items li'),
				{
					opacity: 0,
					y: 20
				},
				{
					opacity: 1,
					y: 0,
					stagger: 0.1,
					duration: 0.5,
					delay: 1,
					ease: 'quart.out'
				}
			);
		} else {
			gsap.fromTo(
				document.querySelectorAll('.items li'),
				{
					opacity: 1,
					y: 0
				},
				{
					opacity: 0,
					y: 20,
					delay: immediate ? 1 : 0,
					stagger: 0.1,
					duration: 0.5,
					ease: 'quart.in'
				}
			);
		}
	}, [isMenuOpen]);

	return (
		<animated.div className={`${className} main-menu`} style={wrapper}>
			{isBelowOrEqual('md') && (
				<button className={'close'} onClick={() => toggleMenu()}>
					{isMenuOpen ? <FontAwesome icon={'times'} /> : <FontAwesome icon={'bars'} />}
				</button>
			)}

			<ul className={'items'}>
				{menu.map((item, i) => (
					<li key={i}>
						<a
							onClick={(e) => {
								e.preventDefault();
								setImmediate(true);
								showLoader({
									navigate: () => navigate(item.slug),
									callback: () => setImmediate(false)
								});
								toggleMenu();
							}}
							href={item.slug}
						>
							{item.title}
						</a>
					</li>
				))}
			</ul>
		</animated.div>
	);
};

const Menu = styled(MenuUnStyled)`
	position: fixed;
	top: 0;
	width: calc(100% - 114px);
	height: 100vh;
	z-index: 990;
	display: flex;
	justify-content: end;
	align-items: center;
	overflow: hidden;
	background-color: ${component.color.primary};
	background-image: url('/assets/images/pattern-1.svg');
	background-size: 100%;

	${() => component.breakpoint.md.mixin('width: 100%; justify-content: start;')}
	${() => component.breakpoint.sm.mixin('width: 100%; justify-content: start; background-size: 150%;')}
    ${() => component.breakpoint.xs.mixin('width: 100%; justify-content: start; background-size: 200%;')}
    ${() => component.breakpoint.xxs.mixin('width: 100%; justify-content: start; background-size: 250%;')}
	
    .close {
		border: none;
		background: none;
		color: ${component.color.white};
		font-size: 24px;
		position: fixed;
		top: 40px;
		right: 40px;
	}

	ul {
		list-style: none;
		padding: 0;
		width: calc(100% - 60%);
		margin-top: 20px;
		margin-bottom: 20px;
		margin-right: 100px;

		${() => component.breakpoint.md.mixin('margin-right: 0; margin-left: 80px; width: 100%;')}
		${() => component.breakpoint.sm.mixin('text-align: left; margin-left: 60px; width: 100%;')}
        ${() => component.breakpoint.xs.mixin('text-align: left; margin-left: 40px; width: 100%;')}
        ${() => component.breakpoint.xxs.mixin('text-align: left; margin-left: 30px; width: 100%;')}
		
        li {
			text-align: right;
			overflow: hidden;
			position: relative;
			height: 80px;

			${() => component.breakpoint.md.mixin('text-align: left;')}
			${() => component.breakpoint.sm.mixin('text-align: left; height: 55px;')}
            ${() => component.breakpoint.xs.mixin('text-align: left; height: 50px;')}
            ${() => component.breakpoint.xxs.mixin('text-align: left; height: 50px;')}
			
            a {
				font-family: ${component.font.family.reckless};
				font-size: 52px;
				font-weight: ${component.font.weight[700]};
				color: ${component.color.white};
				text-decoration: none;
				cursor: pointer;
				transition-duration: 300ms;

				${() => component.breakpoint.sm.mixin('font-size: 36px;')}
				${() => component.breakpoint.xs.mixin('font-size: 32px;')}
				${() => component.breakpoint.xxs.mixin('font-size: 28px;')}
				
                &:hover {
					font-size: 56px;
					transition-duration: 300ms;

					${() => component.breakpoint.sm.mixin('font-size: 38px;')}
					${() => component.breakpoint.xs.mixin('font-size: 34px;')}
                    ${() => component.breakpoint.xxs.mixin('font-size: 30px;')}
				}
			}
		}
	}
`;

export default Menu;
