import { easings, useSpring } from '@react-spring/web';
import { animated, useInView } from '@react-spring/web';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useRef } from 'react';

import FontAwesome from '@component/shared/font-awesome';
import Project from '@component/shared/project';
import Button from '@component/shared/button';

import useData from '@data/useData';

import component from '@config/component';

const FeaturedProjectsUnStyled = ({ className }) => {
	let sliderRef = useRef(null);
	const { getProjects } = useData();

	const featuredProjects = getProjects({ featured: true });

	const [ref, inView] = useInView({
		triggerOnce: false,
		threshold: 0
	});

	const first = useSpring({
		opacity: inView ? 1 : 0,
		x: inView ? '0' : '-200px',
		config: { duration: 600, easing: easings.easeInOutQuart }
	});

	const second = useSpring({
		opacity: inView ? 1 : 0,
		x: inView ? '0' : '200px',
		config: { duration: 600, easing: easings.easeInOutQuart }
	});

	return (
		<div className={className}>
			<Container ref={ref}>
				<div className="header">
					<animated.h2 style={first}>Featured Projects</animated.h2>
					<animated.div className={'navigation'} style={second}>
						<Button href={'/projects'} className={'me-5'}>
							View All
						</Button>
						<div className="arrows">
							<Button
								className={'nav-prev me-3'}
								style={{
									padding: '11px 16px',
									borderRadius: '50%'
								}}
								onClick={() => sliderRef.slickPrev()}
							>
								<FontAwesome icon={'arrow-left'} />
							</Button>
							<Button
								className={'nav-next'}
								style={{
									padding: '11px 16px',
									borderRadius: '50%'
								}}
								onClick={() => sliderRef.slickNext()}
							>
								<FontAwesome icon={'arrow-right'} />
							</Button>
						</div>
					</animated.div>
				</div>
				<div className="contents">
					<Slider
						ref={(slider) => {
							sliderRef = slider;
						}}
						dots={false}
						infinite={true}
						slidesToShow={3}
						slidesToScroll={1}
						centerMode={true}
						focusOnSelect={false}
						responsive={[
							{
								breakpoint: component.breakpoint.md.end,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1,
									infinite: true
								}
							},
							{
								breakpoint: component.breakpoint.sm.end,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
									infinite: true
								}
							},
							{
								breakpoint: component.breakpoint.xs.end,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
									infinite: true
								}
							},
							{
								breakpoint: component.breakpoint.xxs.end,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1,
									infinite: true
								}
							}
						]}
					>
						{featuredProjects.map((item, key) => (
							<Project type={'card'} styleNo={1} project={item} key={key} />
						))}
					</Slider>
				</div>
			</Container>
		</div>
	);
};

const FeaturedProjects = styled(FeaturedProjectsUnStyled)`
	padding: 100px 0;
	
    ${() => component.breakpoint.xs.mixin('padding: 30px 0;')}
    ${() => component.breakpoint.xxs.mixin('padding: 20px 0;')}

	.slick {
		&-slide {
			&:focus,
			&:active {
				border: none !important;
				outline: none !important;
			}
		}

		&-arrow {
			display: none !important;
		}
	}

	.container {
		.header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 40px;

			${() => component.breakpoint.md.mixin('display: block;')}
			${() => component.breakpoint.sm.mixin('display: block; padding-bottom: 0;')}
            ${() => component.breakpoint.xs.mixin('display: block; padding-bottom: 0;')}
            ${() => component.breakpoint.xxs.mixin('display: block; padding-bottom: 0;')}

			h2 {
				font-family: ${component.font.family.reckless};
				font-size: 42px;
				color: ${component.color.primary};
				margin-bottom: 0;

				${() => component.breakpoint.md.mixin('margin-bottom: 25px;')}
				${() => component.breakpoint.sm.mixin('margin-bottom: 25px;font-size: 32px;')}
                ${() => component.breakpoint.xs.mixin('margin-bottom: 25px; font-size: 32px;')}
                ${() => component.breakpoint.xxs.mixin('margin-bottom: 25px;font-size: 28px;')}
			}

			.navigation {
				display: flex;

				${() => component.breakpoint.md.mixin('justify-content: space-between;')}
				${() => component.breakpoint.sm.mixin('display: none;')}
                ${() => component.breakpoint.xs.mixin('display: none;')}
                ${() => component.breakpoint.xxs.mixin('display: none;')}
			}
		}

		.contents {
			width: 100%;

			${() => component.breakpoint.md.mixin('width: 100%;')}
			${() => component.breakpoint.sm.mixin('width: 100%;')}
			${() => component.breakpoint.xs.mixin('width: 100%;')}
			${() => component.breakpoint.xxs.mixin('width: 100%;')}
		}
	}
`;

export default FeaturedProjects;
