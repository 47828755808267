import { useNavigate } from 'react-router-dom';
import { animated } from '@react-spring/web';
import styled from 'styled-components';

import FontAwesome from '@component/shared/font-awesome';

import component from '@config/component';
import useTheme from '@util/hooks/useTheme';

const ButtonUnStyled = (props) => {
	const { showLoader } = useTheme();
	const { className, children = '', icon = {}, href, border, active, loading, ...restButtonProps } = props;
	const { position = 'start', ...restIcon } = icon;

	const navigate = useNavigate();

	let buttonProps = {};

	if (href) buttonProps.onClick = () => showLoader({ navigate: () => navigate(href) });

	return (
		<animated.button className={className} disabled={loading} {...restButtonProps} {...buttonProps}>
			{loading && <FontAwesome icon={'spinner'} animation={'spin'} className={'me-2'} />}
			{position === 'start' && icon && <FontAwesome {...restIcon} />}
			{children}
			{position === 'end' && icon && <FontAwesome {...restIcon} />}
		</animated.button>
	);
};

const Button = styled(ButtonUnStyled)`
	padding: 10px 50px;
	background: ${(props) => (props.active ? component.color.black : component.color.white)};
	color: ${(props) => (props.active ? component.color.white : component.color.black)};
	border: ${(props) => (props.border === false ? `none` : `0.5px solid ${component.color.black}`)};
	border-radius: 50px;
	height: fit-content;

	font-family: ${component.font.family.lato};
	font-weight: ${component.font.weight[400]};
	font-size: 15px;
	text-transform: uppercase;
	transition: 200ms;

	${() => component.breakpoint.xs.mixin('font-size: 13px; padding: 10px 40px;')}
	${() => component.breakpoint.xxs.mixin('font-size: 12px; padding: 10px 40px;')}

	&:hover,
	&:focus,
	&:active {
		scale: 1.05;
	}
`;

export default Button;
