import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import Button from '@component/shared/button';
import component from '@config/component';
import useData from '@data/useData';

const ProjectOneUnStyled = ({ className, project }) => {
	const { getProjectCategory, getProjectStatus } = useData();
	const { name: categoryName } = getProjectCategory(project.category);
	const { name: statusName } = getProjectStatus(project.status);

	return (
		<div className={className}>
			<div className="project">
				<div className="project-background">
					<img
						src={component.image(project.images.vertical, ['f_webp', 'q_auto', 'w_500', 'h_800', 'c_fill'])}
						alt=""
					/>
				</div>

				<div className="project-overlay">
					<div className="project-overlay-content">
						<Button href={`/project/${project.slug}`} className={'content-view'} border={false}>
							View Project
						</Button>
						{
							project.images.hasOwnProperty('logo') &&
							<img src={component.image(project.images.logo, ['f_webp', 'q_auto', 'w_200'])} alt={`${project.name} Logo`}/>
						}
					</div>
				</div>
			</div>
			<div className="project-description">
				<h3 className={'project-description-title'}>{project.name}</h3>
				<p className={'project-description-text'}>
					{categoryName} ○ {statusName}
				</p>
			</div>
		</div>
	);
};

const ProjectOne = styled(ProjectOneUnStyled)`
    padding: 10px;
    height: 680px;

    ${() => component.breakpoint.xl.mixin('height: 650px;')}
    ${() => component.breakpoint.lg.mixin('height: 550px;')}
    ${() => component.breakpoint.md.mixin('height: 550px;')}
    ${() => component.breakpoint.sm.mixin('height: 720px;')}
    ${() => component.breakpoint.xs.mixin('height: 510px;')}
    ${() => component.breakpoint.xxs.mixin('height: 420px;')}
    .project {
        height: 600px;
        position: relative;
        cursor: pointer;
        transition-duration: 400ms;
        border-radius: 6px;
        overflow: hidden;

        ${() => component.breakpoint.xl.mixin('height: 580px;')}
        ${() => component.breakpoint.lg.mixin('height: 480px;')}
        ${() => component.breakpoint.md.mixin('height: 480px;')}
        ${() => component.breakpoint.sm.mixin('height: 640px;')}
        ${() => component.breakpoint.xs.mixin('height: 440px;')}
        ${() => component.breakpoint.xxs.mixin('height: 380px;')}
        &-background {
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, transparent, ${hexToRgba(component.color.primary, 1)});
            transition-duration: 400ms;
            opacity: 0;
            visibility: visible;

            &-content {
                padding: 20px;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;

                button {
                    opacity: 0;
                    visibility: hidden;
                    margin-bottom: -100px;
                    transition-duration: 400ms;
                }

                img {
                    width: 120px;
                    height: 30px;
                    object-fit: scale-down;
                    object-position: left;
                    -webkit-filter: drop-shadow(0 0 0 #ffffff);
                    filter: drop-shadow(1px 1px 0 #ffffff);
                }
            }
        }

        &-description {
            &-title {
                margin-top: 6px;
                margin-bottom: 2px;
                font-family: ${component.font.family.reckless};
                font-weight: ${component.font.weight[700]};
                font-size: 28px;
                transition-duration: 400ms;

                ${() => component.breakpoint.xl.mixin('font-size: 24px;')}
                ${() => component.breakpoint.lg.mixin('font-size: 24px;')}
                ${() => component.breakpoint.md.mixin('font-size: 23px;')}
                ${() => component.breakpoint.sm.mixin('font-size: 22px;')}
                ${() => component.breakpoint.xs.mixin('font-size: 20px;')}
                ${() => component.breakpoint.xxs.mixin('font-size: 18px;')}
            }

            &-text {
                margin-bottom: 0;
                font-family: ${component.font.family.poppins};
                font-weight: ${component.font.weight[300]};
                transition-duration: 400ms;

                ${() => component.breakpoint.xl.mixin('font-size: 14px;')}
                ${() => component.breakpoint.lg.mixin('font-size: 14px;')}
                ${() => component.breakpoint.md.mixin('font-size: 14px;')}
                ${() => component.breakpoint.sm.mixin('font-size: 14px;')}
                ${() => component.breakpoint.xs.mixin('font-size: 14px;')}
                ${() => component.breakpoint.xxs.mixin('font-size: 12px;')}
            }
        }
    }

    &:hover {
        .project {
            box-shadow: rgba(0, 0, 0, 0.6) 0 25px 20px -20px;
            scale: 1.03;
            z-index: 999;

            &-overlay {
                opacity: 1;
                visibility: visible;
                z-index: 999;

                &-content {
                    button {
                        opacity: 1;
                        visibility: visible;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .project-description {
            position: absolute;
            z-index: 1000;

            &-title {
                margin-top: -100px;
                margin-left: 25px;
                color: ${component.color.white};
            }

            &-text {
                opacity: 0;
            }
        }
    }
`;

export default ProjectOne;
