import { Col, Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import axios from 'axios';

import Breadcrumb from '@component/layout/main/breadcrumb';

import Button from '@component/shared/button';

import component from '@config/component';
import useData from '@data/useData';

const ContactUnStyled = ({ className }) => {
	const [formData, setFormData] = useState({ name: '', contact: '', message: '' });
	const [errors, setErrors] = useState({});
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const { getPage, socials } = useData();
	const { breadcrumb } = getPage('contact');

	useEffect(() => {
		setTimeout(() => {
			if (message) setMessage('');
		}, 5000);
	}, [message]);

	const submitForm = (e) => {
		e.preventDefault();
		setLoading(true);
		setErrors({});
		axios
			.post(component.makeApiEndpoint('contact/message'), formData)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					setMessage(data.message);
					setFormData({ name: '', contact: '', message: '' });
				}
			})
			.catch((err) => {
				const { data } = err.response;
				const errs = {};
				if (data.hasOwnProperty('errors') && data.errors.length > 0)
					data.errors.forEach((error) => (errs[error.path] = error.message));
				setErrors(errs);
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={className}>
			<Breadcrumb title={breadcrumb.title} background={breadcrumb.background} />
			<Container className={'py-5 mb-5'}>
				<div className="section">
					<div className="title">Mail Us</div>
					<div className="content">
						<a href={'mailto:hello@domicile.com.bd'} target={'_blank'} rel={'noreferrer'}>
							hello@domicile.com.bd
						</a>
						<a href={'mailto:contact@domicile.com.bd'} target={'_blank'} rel={'noreferrer'}>
							contact@domicile.com.bd
						</a>
					</div>
				</div>
				<div className="section">
					<div className="title">Call Us</div>
					<div className="content">
						<a href={'tel:+8801550006606'} target={'_blank'} rel={'noreferrer'}>
							(+880) 155 000 6606
						</a>
						<a href={'tel:+8801550006564'} target={'_blank'} rel={'noreferrer'}>
							(+880) 155 000 6564
						</a>
					</div>
				</div>
				<div className="section">
					<div className="title">Social Media</div>
					<div className="content">
						<a href={socials.facebook} target={'_blank'} rel={'noreferrer'}>
							Facebook
						</a>
						<a href={socials.instagram} target={'_blank'} rel={'noreferrer'}>
							Instagram
						</a>
						<a href={socials.linkedin} target={'_blank'} rel={'noreferrer'}>
							Linkedin
						</a>
					</div>
				</div>
			</Container>

			<Container className={'py-5'}>
				<Row className={'g-5'}>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h1 className={'form-title text-right'}>Contact Us</h1>
						{message && <div className="form-control-success">{message}</div>}
						<form onSubmit={submitForm}>
							<div className="form-control">
								<label htmlFor="name">Name*</label>
								<input
									type="text"
									value={formData.name}
									onChange={(e) => setFormData({ ...formData, name: e.target.value })}
								/>
								{errors.hasOwnProperty('name') && (
									<div className="form-control-error">{errors.name}</div>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="contact">Contact Number*</label>
								<input
									type="tel"
									value={formData.contact}
									onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
								/>
								{errors.hasOwnProperty('contact') && (
									<div className="form-control-error">{errors.contact}</div>
								)}
							</div>

							<div className="form-control">
								<label htmlFor="message">Message*</label>
								<textarea
									rows={5}
									value={formData.message}
									onChange={(e) => setFormData({ ...formData, message: e.target.value })}
								/>
								{errors.hasOwnProperty('message') && (
									<div className="form-control-error">{errors.message}</div>
								)}
							</div>

							<div className="submitter d-flex justify-content-end">
								<Button
									className={'me-3'}
									icon={{ position: 'end', icon: 'rotate', className: 'ms-2' }}
								>
									Reset
								</Button>
								<Button
									type={'submit'}
									active={true}
									loading={loading}
									icon={{ position: 'end', icon: 'arrow-right', className: 'ms-2' }}
								>
									Submit
								</Button>
							</div>
						</form>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<img
							src={component.image('38be7ea5ebfe9d274ab0215b7447d595_mxjr3b', [
								'f_webp',
								'q_auto',
								'w_800',
								'h_500',
								'c_fill'
							])}
							alt="home Interior"
							style={{ borderRadius: '6px', objectFit: 'cover' }}
							width={'100%'}
							height={'550px'}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const Contact = styled(ContactUnStyled)`
	.section {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid ${hexToRgba(component.color.black, 0.2)};
		padding: 30px 10px;

		&:last-child {
			border-bottom: none;
		}

		.title {
			font-family: ${component.font.family.reckless};
			font-weight: ${component.font.weight[400]};
			font-size: 24px;
		}

		.content {
			text-align: right;

			a {
				display: block;
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				font-size: 16px;
				margin-bottom: 6px;
				text-decoration: none;
				color: ${component.color.primary};
			}
		}
	}

	.form {
		&-title {
			font-family: ${component.font.family.reckless};
			font-size: 42px;
			margin-bottom: 30px;
		}

		&-control {
			border: none !important;
			padding: 0;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			label {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[500]};
				margin-bottom: 5px;
			}

			input,
			textarea {
				padding: 8px 0;
				border: 0;
				border-bottom: 1px solid ${hexToRgba(component.color.black, 0.4)};
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: ${component.color.black};

				&::placeholder {
					font-family: ${component.font.family.poppins};
					font-weight: ${component.font.weight[300]};
					color: ${hexToRgba(component.color.black, 0.4)};
				}

				&:hover,
				&:focus,
				&:active {
					outline: none;
					box-shadow: none;
				}
			}

			&-error {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: #f60b0b;
				font-size: 14px;
				margin-top: 10px;
			}

			&-success {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: #42dd00;
				font-size: 14px;
				margin-bottom: 10px;
			}
		}
	}
`;

export default Contact;
