import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Lightbox from 'yet-another-react-lightbox-lite';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import component from '@config/component';
import useData from '@data/useData';

import { default as Projects } from '@component/shared/project';
import CallToAction from '@component/shared/call-to-action';
import PreFooter from '@component/shared/prefooter';
import Button from '@component/shared/button';

import Breadcrumb from '@component/layout/main/breadcrumb';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

const ProjectUnStyled = ({ className }) => {
	const { slug } = useParams();
	const { getSingleProject, getProjects, getProjectStatuses } = useData();
	const { project, getCategory, getGallery } = getSingleProject(slug);

	const [status, setStatus] = useState('');
	const [lightBox, setLightBox] = useState({ open: false, index: 0 });

	return (
		<div className={className}>
			<Breadcrumb
				title={project.name}
				subtitle={project.address.full}
				logo={project.images.logo}
				background={project.images.cover || project.images.featured}
			/>

			<Container className={'py-5'}>
				<h2 className={'title mb-5'}>Specification</h2>
				<Row className={'g-4'}>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Address</h3>
						<p className={'text'}>{project.address.full}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Land Area</h3>
						<p className={'text'}>{project.area}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Height</h3>
						<p className={'text'}>{project.height}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Number Of Floor</h3>
						<p className={'text'}>{project.floor}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Building Type</h3>
						<p className={'text'}>{getCategory().name}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Building Face</h3>
						<p className={'text'}>{project.face}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Number Of Apartments</h3>
						<p className={'text'}>{project.apartment.total}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Number Of Car Parking</h3>
						<p className={'text'}>{project.parking || 0}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Units Per Floor</h3>
						<p className={'text'}>{project.apartment.unit || 0}</p>
					</Col>
					<Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
						<h3 className={'subtitle'}>Size Of Each Apartment</h3>
						<p className={'text'}>{project.apartment.sizes}</p>
					</Col>
				</Row>
			</Container>

			<Container className={'py-5 mb-5'}>
				<h2 className={'title mb-5'}>Showcase</h2>
				<ResponsiveMasonry
					columnsCountBreakPoints={{
						[component.breakpoint.xs.start]: 1,
						[component.breakpoint.sm.start]: 2,
						[component.breakpoint.md.start]: 3
					}}
				>
					<Masonry columnsCount={3} gutter={'1rem'}>
						{getGallery().map((image, index) => (
							<img
								src={component.image(image, ['f_webp', 'q_80'])}
								alt={project.name}
								key={index}
								style={{ width: '100%', display: 'block' }}
								onClick={() => setLightBox({ open: true, index })}
							/>
						))}
					</Masonry>
				</ResponsiveMasonry>

				{lightBox.open && (
					<Lightbox
						slides={getGallery().map((item) => ({ src: component.image(item, ['f_webp', 'q_80']) }))}
						index={lightBox.index}
						setIndex={(i) => setLightBox({ open: true, index: i })}
						onClose={() => setLightBox({ open: false, index: 0 })}
					/>
				)}
			</Container>

			{project?.location?.lat && project?.location?.lng && (
				<Container className={'py-5 mb-5'}>
					<h2 className={'title mb-5'}>Location On Map</h2>
					<GoogleMap
						mapContainerStyle={{
							width: '100%',
							height: '600px',
							borderRadius: '10px'
						}}
						center={{
							lat: project.location.lat,
							lng: project.location.lng
						}}
						zoom={10}
					>
						<MarkerF
							position={{ lat: project.location.lat, lng: project.location.lng }}
							draggable={false}
							icon={'/assets/images/pin.png'}
						/>
					</GoogleMap>
				</Container>
			)}

			<CallToAction
				title={"Ready to Transform Your Space? Let's Get Started with Domicile!"}
				button={{ children: 'Call Now', href: '/contact' }}
			/>

			<Container className={'py-5 mb-5 mt-5'}>
				<h2 className={'title mb-3'}>Related Projects</h2>
				<span className="d-flex mb-5">
					<Button
						className={'me-3'}
						style={{ padding: '6px 30px' }}
						active={status === ''}
						onClick={() => setStatus('')}
					>
						All
					</Button>
					{getProjectStatuses().map((s, i) => {
						return (
							<Button
								key={i}
								className={'me-3'}
								style={{ padding: '6px 30px' }}
								active={s.slug === status}
								onClick={() => setStatus(s.slug)}
							>
								{s.name}
							</Button>
						);
					})}
				</span>
				<Row className={'g-3'}>
					{getProjects({ category: project.category, status: status, ignore: slug }).map((project, index) => {
						return (
							<Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} key={index}>
								<Projects styleNo={2} project={project} />
							</Col>
						);
					})}
				</Row>
			</Container>

			<PreFooter
				bg={component.image('e5da84127843791.617185b3e53c4_y5xozy', [
					'f_webp',
					'q_auto',
					'w_1920',
					'h_600',
					'c_fill'
				])}
				content={'Building Dreams, One Home at a Time\nStart Your Journey with Domicile'}
			/>
		</div>
	);
};

const Project = styled(ProjectUnStyled)`
	.title {
		font-family: ${component.font.family.reckless};
		font-size: 42px;
	}

	.subtitle {
		font-family: ${component.font.family.reckless};
		font-size: 22px;
		color: ${hexToRgba(component.color.black, 0.5)};
	}

	.text {
		font-family: ${component.font.family.reckless};
		font-size: 16px;
		white-space: pre-line;
	}

	img {
		cursor: pointer;
		border-radius: 6px;
	}
`;

export default Project;
