import { Container, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

import FontAwesome from '@component/shared/font-awesome';

import useBreakpoint from '@util/hooks/useBreakpoint';

import component from '@config/component';
import useTheme from '@util/hooks/useTheme';

const HeaderUnStyled = ({ className }) => {
	const cls = classNames(className);
	const navigate = useNavigate();
	const location = useLocation();

	const { is } = useBreakpoint();
	const { isMenuOpen, toggleMenu, showLoader } = useTheme();

	return (
		<div className={cls}>
			<Container>
				<Navbar expand="lg">
					<Navbar.Brand
						onClick={() => {
							if (location.pathname === '/') return;
							showLoader({ navigate: () => navigate('/') });
						}}
					>
						<img src={'/assets/images/logo/logo-2.svg'} alt={'Domicile Logo'} width={160} />
					</Navbar.Brand>

					{is(['xxs', 'xs', 'sm', 'md']) && (
						<span className={'d-flex align-items-center'}>
							<button className={'menu'} onClick={() => toggleMenu()}>
								{isMenuOpen ? <FontAwesome icon={'times'} /> : <FontAwesome icon={'bars'} />}
							</button>
						</span>
					)}
				</Navbar>
			</Container>
		</div>
	);
};

const Header = styled(HeaderUnStyled)`
	height: 97px;
	position: absolute;
	z-index: 980;
	width: 100%;

	.container {
		height: 97px;

		.nav {
			&bar {
				padding: 0 !important;
				display: flex;
				justify-content: space-between;
				height: 100%;

				&-brand {
					cursor: pointer;
				}
			}
		}
	}

	.menu {
		background: none;
		border: none;
		margin: 2px 0 0 15px;
		padding: 0;

		& i {
			color: ${component.color.white};
			font-size: 24px;
		}
	}
`;

export default Header;
