import classNames from 'classnames';

const FontAwesome = (props) => {
	const { type = 'regular', icon, size, animation, className, ...others } = props;
	const animationList = ['spin', 'spin-pulse', 'pulse'];
	const cls = classNames(
		{
			[`fa-${type}`]: type !== undefined,
			[`fa-${icon}`]: icon !== undefined,
			[`fa-${size}`]: size !== undefined,
			[`fa-${animation}`]: animation !== undefined && animationList.includes(animation)
		},
		className
	);

	return (
		<>
			<i className={cls} {...others} />
		</>
	);
};

export default FontAwesome;
