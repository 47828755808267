import data from '@data/index.json';
import _ from 'lodash';

const useData = () => {
	const getSliders = () => {
		if (data.hasOwnProperty('sliders')) return data.sliders;
		else return [];
	};

	const getMenu = () => {
		if (data.hasOwnProperty('menu')) return data.menu;
		else return [];
	};

	const getPage = (name) => {
		if (data.hasOwnProperty('pages') && data.pages.hasOwnProperty(name)) return data.pages[name];
		else return [];
	};

	const getProject = () => {
		if (data.hasOwnProperty('project')) return data.project;
		else return {};
	};

	const getSocials = () => {
		if (data.hasOwnProperty('socials')) return data.socials;
		else return {};
	};

	const getJobs = () => {
		if (data.hasOwnProperty('jobs')) return data.jobs;
		else return {};
	};

	const getJob = (slug) => {
		const jobs = getJobs();
		return _.find(jobs, (r) => r.slug === slug);
	};

	const getProjects = (queries = {}) => {
		const project = getProject();

		let allProjects = [];
		let projects = [];

		if (project.hasOwnProperty('list')) allProjects = projects = project.list;

		if (queries !== null)
			projects = _.filter(allProjects, (r) => {
				const result = _.map(queries, (value, key) => {
					if (!value) return true;
					if (key === 'name') return _.includes(_.lowerCase(r[key]), _.lowerCase(value));
					if (key === 'location') return _.includes(_.lowerCase(r[key].tags), _.lowerCase(value));
					if (key === 'ignore') return r.slug !== value;
					return r[key] === value;
				});
				return !result.includes(false);
			});

		return projects;
	};

	const getSingleProject = (slug) => {
		const projects = getProjects();
		const project = _.find(projects, (r) => r.slug === slug);

		const getImages = () => {
			if (project?.hasOwnProperty('images')) return project.images;
			else return {};
		};

		const getVerticalImage = () => {
			const { vertical = '' } = getImages();
			return vertical;
		};

		const getFeaturedImage = () => {
			const { featured = '' } = getImages();
			return featured;
		};

		const getGallery = () => {
			const { gallery = [] } = getImages();
			return gallery;
		};

		const getCategory = () => {
			return getProjectCategory(project.category);
		};

		const getStatus = () => {
			return getProjectStatus(project.status);
		};

		return { project, getImages, getVerticalImage, getFeaturedImage, getGallery, getCategory, getStatus };
	};

	const getProjectSizes = () => {
		const sizes = [];
		const data = getProjects();

		data.forEach((item) => {
			item.apartment.allSizes.forEach((size) => {
				!sizes.includes(size) && sizes.push(size);
			});
		});

		sizes.sort();
		return sizes;
	};

	const getProjectLocations = () => {
		const locations = [];
		const data = getProjects();

		data.forEach((item) => {
			item.location.tags.forEach((size) => {
				!locations.includes(size) && locations.push(size);
			});
		});

		locations.sort();
		return locations;
	};

	const getProjectCategories = () => {
		const { categories = [] } = getProject();
		return categories;
	};

	const getProjectCategory = (slug) => {
		const categories = getProjectCategories();
		return _.find(categories, (r) => r.slug === slug);
	};

	const getProjectStatuses = (push) => {
		const { statuses = [] } = getProject();
		return statuses;
	};

	const getProjectStatus = (slug) => {
		const statuses = getProjectStatuses();
		return _.find(statuses, (r) => r.slug === slug);
	};

	return {
		getSliders,
		sliders: getSliders(),

		getMenu,
		menu: getMenu(),

		getSocials,
		socials: getSocials(),

		getJobs,
		jobs: getJobs(),
		getJob,

		getPage,
		getProject,

		getProjects,
		projects: getProjects(),
		getSingleProject,

		getProjectCategories,
		getProjectSizes,
		getProjectLocations,
		getProjectCategory,
		getProjectStatuses,
		getProjectStatus
	};
};

export default useData;
