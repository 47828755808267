import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';

import Breadcrumb from '@component/layout/main/breadcrumb';

import Job from '@page/career/components/job';

import useData from '@data/useData';

const CareerUnStyled = ({ className }) => {
	const classes = classNames(className, 'career');

	const { getPage, jobs } = useData();
	const { breadcrumb } = getPage('career');

	return (
		<div className={classes}>
			<Breadcrumb title={breadcrumb.title} background={breadcrumb.background} />

			<Container className={'py-5'}>
				<Row className={'g-4'}>
					{jobs.map((job, i) => {
						return (
							<Col key={i} xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
								<Job job={job} />
							</Col>
						);
					})}
				</Row>
			</Container>
		</div>
	);
};

const Career = styled(CareerUnStyled)``;

export default Career;
