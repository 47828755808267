import styled from 'styled-components';

import component from '@config/component';
import hexToRgba from 'hex-to-rgba';

import { gsap } from 'gsap';
import { SplitText } from 'gsap-trial/SplitText';
import { useGSAP } from '@gsap/react';
import classNames from 'classnames';

gsap.registerPlugin(SplitText);

const SplitSectionUnStyled = ({ className, title, text, quote = true }) => {
	const classes = classNames(className, 'split-section');
	const selectorClasses = `.${classes.split(' ').join('.')}`;

	useGSAP(() => {
		const headingTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: selectorClasses,
				start: 'top 100%',
				toggleActions: 'play pause resume reset'
			}
		});
		const heading = new SplitText(`${selectorClasses} .first h3`, {
			type: 'words,chars',
			lineThreshold: 0.5,
			reduceWhiteSpace: true
		});
		gsap.set(`${selectorClasses} .first h3`, { perspective: 200 });

		const contentTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: selectorClasses,
				start: 'top 100%',
				toggleActions: 'play pause resume reset'
			}
		});
		const content = new SplitText(`${selectorClasses} .second p`, {
			type: 'words,chars',
			lineThreshold: 0.5,
			reduceWhiteSpace: true
		});
		gsap.set(`${selectorClasses} .second p`, { perspective: 100 });

		headingTimeline
			.from(heading.chars, {
				duration: 0.8,
				opacity: 0,
				y: 80,
				ease: 'power1.inOut',
				delay: 0.6,
				stagger: 0.08
			})
			.to(heading.chars, {
				opacity: 1,
				y: 0
			});

		contentTimeline
			.from(content.words, {
				duration: 0.8,
				opacity: 0,
				y: 30,
				ease: 'power1.inOut',
				delay: 0.6,
				stagger: 0.02
			})
			.to(content.lines, {
				opacity: 1,
				y: 0
			});
	}, []);

	return (
		<div className={classes}>
			<div className="first p-5">
				<h3>{title}</h3>
			</div>
			<div className="second">
				{quote && (
					<div className="quote-start">
						<span>“</span>
					</div>
				)}

				<p>{text}</p>

				{quote && (
					<div className="quote-end">
						<span>”</span>
					</div>
				)}
			</div>
		</div>
	);
};

const SplitSection = styled(SplitSectionUnStyled)`
	height: 500px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	${() => component.breakpoint.sm.mixin('display: block; height: 100%;')}
	${() => component.breakpoint.xs.mixin('display: block; height: 100%;')}
    ${() => component.breakpoint.xxs.mixin('display: block; height: 100%;')}

	.first {
		background-color: ${component.color.primary};
		background-image: url('/assets/images/pattern-${(props) => props.pattern || 2}.svg');
		background-size: 150%;
		background-position: center;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		${() => component.breakpoint.xxl.mixin('width: 40%;')}
		${() => component.breakpoint.xl.mixin('width: 40%;')}
        ${() => component.breakpoint.lg.mixin('width: 40%;')}
        ${() => component.breakpoint.md.mixin('width: 40%;')}
        ${() => component.breakpoint.sm.mixin('width: 100%; height: 300px;')}
        ${() => component.breakpoint.xs.mixin('width: 100%; height: 300px;')}
        ${() => component.breakpoint.xxs.mixin('width: 100%; height: 300px;')}

		h3 {
			color: ${component.color.white};
			font-family: ${component.font.family.reckless};
			font-weight: ${component.font.weight[400]};
			margin-bottom: 0;

			${() => component.breakpoint.xxl.mixin('font-size: 76px;')}
			${() => component.breakpoint.xl.mixin('font-size: 60px;')}
			${() => component.breakpoint.lg.mixin('font-size: 46px;')}
			${() => component.breakpoint.md.mixin('font-size: 38px;')}
			${() => component.breakpoint.sm.mixin('font-size: 50px; text-align: center;')}
			${() => component.breakpoint.xs.mixin('font-size: 40px; text-align: center;')}
			${() => component.breakpoint.xxs.mixin('font-size: 30px; text-align: center;')}
		}
	}

	.second {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		${() => component.breakpoint.xxl.mixin('width: 60%; padding: 100px;')}
		${() => component.breakpoint.xl.mixin('width: 60%; padding: 80px;')}
        ${() => component.breakpoint.lg.mixin('width: 60%; padding: 60px;')}
        ${() => component.breakpoint.md.mixin('width: 60%; padding: 40px;')}
        ${() => component.breakpoint.sm.mixin('width: 100%; padding: 40px; height: 350px')}
        ${() => component.breakpoint.xs.mixin('width: 100%; padding: 40px; height: 350px')}
        ${() => component.breakpoint.xxs.mixin('width: 100%; padding: 40px; height: 350px')}
		
		p {
			font-family: ${component.font.family.poppins};
			font-weight: ${component.font.weight[200]};
			margin-bottom: 0;
			position: relative;
			text-align: justify;

			${() => component.breakpoint.xxl.mixin('font-size: 20px;')}
			${() => component.breakpoint.xl.mixin('font-size: 18px;')}
			${() => component.breakpoint.lg.mixin('font-size: 18px;')}
			${() => component.breakpoint.md.mixin('font-size: 17px;')}
			${() => component.breakpoint.sm.mixin('font-size: 15px;')}
			${() => component.breakpoint.xs.mixin('font-size: 13px;')}
			${() => component.breakpoint.xxs.mixin('font-size: 11px;')}
		}

		${(props) =>
			props.quote !== false &&
			`
				.quote {
					&-start,
					&-end {
						span {
							font-family: ${component.font.family.reckless};
							font-size: 256px;
							font-weight: ${component.font.weight[400]};
							color: ${hexToRgba(component.color.primary, 0.1)};
							line-height: 0 !important;
							margin-top: 120px;
		
							${() => component.breakpoint.sm.mixin('font-size: 200px; margin-top: 100px;')}
							${() => component.breakpoint.xs.mixin('font-size: 180px; margin-top: 80px;')}
		                    ${() => component.breakpoint.xxs.mixin('font-size: 140px; margin-top: 70px;')}
						}
					}
		
					&-start {
						position: absolute;
						left: 60px;
						top: 0;
						height: 100px;
						width: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
		
						${() => component.breakpoint.sm.mixin('left: 30px')}
						${() => component.breakpoint.xs.mixin('left: 30px')}
		                ${() => component.breakpoint.xxs.mixin('left: 10px')}
					}
		
					&-end {
						position: absolute;
						bottom: 0;
						right: 60px;
						height: 100px;
						width: 100px;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
		
						${() => component.breakpoint.sm.mixin('right: 30px')}
						${() => component.breakpoint.xs.mixin('right: 30px')}
		                ${() => component.breakpoint.xxs.mixin('right: 10px')}
					}
				}
			`}
	}
`;

export default SplitSection;
