import styled from 'styled-components';

import Button from '@component/shared/button';
import classNames from 'classnames';
import component from '@config/component';

const HeroButtonUnStyled = ({ button, className, api, ...restProps }) => {
	const { link, text, ...restButtonProps } = button;
	const classes = classNames(className, 'hero-button');

	return (
		<Button className={classes} href={link || ''} {...restProps} {...restButtonProps}>
			{text}
		</Button>
	);
};

const HeroButton = styled(HeroButtonUnStyled)`
    ${() => component.breakpoint.xs.mixin('display: none;')}
    ${() => component.breakpoint.xxs.mixin('display: none;')}
`;

export default HeroButton;
