import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import Breadcrumb from '@component/layout/main/breadcrumb';
import Highlight from '@component/shared/highlight';

import PreFooter from '@component/shared/prefooter';
import component from '@config/component';

import useData from '@data/useData';

const AboutUnStyled = ({ className }) => {
	const { getPage } = useData();
	const { breadcrumb } = getPage('about');

	return (
		<div className={className}>
			<Breadcrumb title={breadcrumb.title} background={breadcrumb.background} />
			<Container className={'py-5'}>
				<h2 className={'mb-4'}>Crafting Beautiful and Functional Spaces</h2>
				<p>
					Welcome to Domicile, where we believe that a home is more than just a place—it's a feeling. Founded
					with a passion for creating spaces that inspire and comfort, Domicile is dedicated to transforming
					houses into homes. Our journey began with a simple idea: to make every home a sanctuary of beauty
					and functionality. Over the years, we have grown into a trusted name in the real estate industry,
					known for our commitment to excellence and our unwavering dedication to our clients.
				</p>
				<p>
					At Domicile, we understand that buying, selling, or investing in real estate is more than just a
					transaction—it's a life-changing experience. That's why we are here to guide you every step of the
					way with expert advice and unparalleled service. Our team of dedicated real estate professionals
					brings a wealth of knowledge and experience to the table, ensuring that you receive the best
					possible guidance and support throughout your real estate journey.
				</p>
				<p>
					We take pride in offering personalized solutions that fit your unique needs and goals. Our
					comprehensive knowledge of the local area, cutting-edge marketing strategies, and deep industry
					connections make us the go-to resource for all things real estate. Whether you're looking to find
					your dream home, sell your property for the best price, or make a smart investment, Domicile is here
					to help you achieve your real estate aspirations with ease and confidence.
				</p>

				<img
					src={component.image('771a85146328677.62ae7c79e4a95-min_tjxbsz', [
						'f_webp',
						'q_auto',
						'w_1300',
						'h_600',
						'c_fill'
					])}
					height={600}
					alt={'About us'}
					className={'mt-5 mb-4'}
					loading={'lazy'}
				/>

				<h2 className={'mt-5 mb-4'}>Our Mission</h2>
				<p>
					At Domicile, our mission is to provide exceptional home solutions that cater to the unique needs and
					tastes of our clients. We strive to blend functionality with aesthetics, ensuring that every space
					we design is both beautiful and practical. Our goal is to create environments that enhance the
					quality of life for our clients, making their homes a true reflection of their personalities and
					lifestyles. We are committed to delivering innovative and sustainable solutions that not only meet
					but exceed our clients' expectations.
				</p>
				<p>
					We believe that every home should be a sanctuary, a place where individuals and families can thrive
					and create lasting memories. Our mission is to transform houses into homes by offering personalized
					services that are tailored to the unique needs and preferences of each client. We take the time to
					understand our clients' visions and work collaboratively to bring those visions to life. Our
					dedication to quality, innovation, and customer satisfaction drives us to continuously improve and
					evolve our offerings.
				</p>
				<p>
					At Domicile, we are passionate about making a positive impact on the communities we serve. We are
					committed to sustainable practices and environmentally responsible design, ensuring that our
					projects contribute to a healthier planet. Our mission is to create homes that are not only
					beautiful and functional but also sustainable and eco-friendly. We believe that by embracing
					sustainability, we can create a better future for generations to come.
				</p>

				<img
					src={component.image('56b11674e46f615b2b02f208414175ee_lsvh4e', [
						'f_webp',
						'q_auto',
						'w_1300',
						'h_600',
						'c_fill'
					])}
					height={600}
					alt={'About us'}
					className={'mt-5 mb-4'}
					loading={'lazy'}
				/>

				<h2 className={'mt-5 mb-4'}>Our Vision</h2>
				<p>
					At Domicile, our vision is to be the leading real estate company that transforms lives by providing
					exceptional service, innovative solutions, and unmatched market expertise. We strive to create
					sustainable communities where people can find not just a house, but a place to call home. Our goal
					is to foster growth, happiness, and long-term value for our clients and the communities we serve. We
					envision a future where every client experiences the joy and satisfaction of living in a home that
					truly reflects their dreams and aspirations.
				</p>
				<p>
					We are committed to setting new standards in the real estate industry by embracing cutting-edge
					technology and sustainable practices. Our vision includes leveraging advanced tools and insights to
					provide seamless, efficient, and transparent transactions. We aim to be at the forefront of
					innovation, continuously improving our services to meet the evolving needs of our clients. By
					prioritizing sustainability, we seek to create environmentally responsible developments that
					contribute to a healthier planet and a better quality of life for all.
				</p>
				<p>
					At Domicile, we believe in the power of community and the importance of building lasting
					relationships. Our vision is to be more than just a real estate company; we aspire to be a trusted
					partner and a positive force in the lives of our clients and the communities we serve. We are
					dedicated to making a meaningful impact through our work, ensuring that every project we undertake
					leaves a lasting legacy of excellence, integrity, and care.
				</p>
			</Container>

			<img
				src={component.image('49d23a127765023.6148cad26723b_rgvcuy', [
					'f_webp',
					'q_auto',
					'w_1920',
					'h_800',
					'c_fill'
				])}
				height={800}
				alt={'About us'}
				className={'mt-5 mb-4'}
				loading={'lazy'}
			/>

			<Container className={'py-5'}>
				<div className="section">
					<div className="title">Highlights</div>
					<div className="content">
						<p>
							We pride ourselves on our pragmatic approach to design, ensuring that each project is
							handled with meticulous attention to its unique requirements and imperatives. Our expertise
							extends to working in conservation areas and on listed buildings, where we aim to respect
							and retain the best of the historic elements while supplementing them with contemporary
							interventions wherever appropriate. Energy efficiency and sustainability are at the
							forefront of our design process, particularly for new-build projects. By considering these
							factors from the outset, we add present and future value to our projects, ensuring they are
							both environmentally responsible and economically beneficial.
						</p>
						<p>
							With over 30 years of experience in the real estate industry, our team has successfully
							delivered more than 300 projects, averaging over 25 projects per year. Our comprehensive
							knowledge of the local market, cutting-edge marketing strategies, and deep industry
							connections make us the go-to resource for all things real estate. We are passionate about
							helping our clients succeed and are committed to making their real estate experience as
							smooth and stress-free as possible. Our dedication to excellence, innovation, and customer
							satisfaction has redefined elegance in the Bangladeshi real estate landscape.
						</p>
					</div>
				</div>
				<Row className={'g-5 mb-5'}>
					<Col xxl={4} xl={4} lg={4} md={4} sm={12}>
						<Highlight title={'15+'} subtitle={'Years of experience'} />
					</Col>
					<Col xxl={4} xl={4} lg={4} md={4} sm={12}>
						<Highlight title={'70+'} subtitle={'Projects delivered'} />
					</Col>
					<Col xxl={4} xl={4} lg={4} md={4} sm={12}>
						<Highlight title={'10+'} subtitle={'Projects per year'} />
					</Col>
				</Row>
			</Container>

			<PreFooter
				bg={component.image('d_pvz2yz', ['f_webp', 'q_auto', 'w_1920', 'h_600', 'c_fill'])}
				content={'Transforming Spaces, Enriching Lives\nYour Perfect Home Awaits with Domicile'}
			/>
		</div>
	);
};

const About = styled(AboutUnStyled)`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${component.font.family.reckless};
	}

	h1 {
		font-size: 42px;
	}

	& h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 30px;
	}

	h4 {
		font-size: 24px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 12px;
	}

	p {
		font-family: ${component.font.family.poppins};
		font-weight: ${component.font.weight[300]};
		line-height: 26px;
		font-size: 14px;
		text-align: justify;
	}

	img {
		width: 100%;
		object-fit: cover;
	}

	ul {
		li {
			font-family: ${component.font.family.poppins};
			font-weight: ${component.font.weight[300]};
			line-height: 26px;
			font-size: 14px;
			text-align: justify;
		}
	}

	.section {
		margin-bottom: 40px;

		.title {
			font-family: ${component.font.family.reckless};
			font-size: 48px;
			margin-bottom: 20px;
		}

		.content {
			text-align: justify-all;

			p {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				font-size: 14px;
				text-align: justify;
			}
		}
	}
`;

export default About;
