import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';

import component from '@config/component';

import { gsap } from 'gsap';
import { SplitText } from 'gsap-trial/SplitText';
import { useGSAP } from '@gsap/react';
import { Container } from 'react-bootstrap';

gsap.registerPlugin(SplitText);

const PreFooterUnStyled = ({ className, bg, content }) => {
	useGSAP(() => {
		const headingTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: '.pre-footer',
				start: 'top 100%',
				toggleActions: 'play pause resume reset'
			}
		});
		const heading = new SplitText('.pre-footer-title', { type: 'words,chars' });

		headingTimeline
			.from(heading.words, {
				duration: 0.5,
				opacity: 0,
				y: 80,
				delay: 0.6,
				stagger: 0.1,
				ease: 'quart'
			})
			.to(heading.chars, {
				opacity: 1,
				y: 0
			});
	});

	return (
		<div className={`${className} pre-footer`}>
			<img src={bg} alt="backgroundImage" height={600} />
			<div className="overlay">
				<Container>
					<h2 className={'pre-footer-title'}>{content}</h2>
				</Container>
			</div>
		</div>
	);
};

const PreFooter = styled(PreFooterUnStyled)`
	position: relative;
	height: 600px;
	overflow: hidden;

	${() => component.breakpoint.sm.mixin('height: 506px;')}
	${() => component.breakpoint.xs.mixin('height: 500px;')}
    ${() => component.breakpoint.xxs.mixin('height: 400px;')}
	
	.container {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: start;
		align-items: center;
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${hexToRgba(component.color.primary, 0.8)};

		${() => component.breakpoint.xl.mixin('padding: 0 80px;')}
		${() => component.breakpoint.lg.mixin('padding: 0 60px;')}
		${() => component.breakpoint.md.mixin('padding: 0 40px;')}
		${() => component.breakpoint.sm.mixin('padding: 0 40px;')}
		${() => component.breakpoint.xs.mixin('padding: 0 40px;')}
        ${() => component.breakpoint.xxs.mixin('padding: 0 30px;')}

		h2 {
			font-family: ${component.font.family.reckless};
			font-weight: ${component.font.weight[300]};
			font-size: 42px;
			text-transform: uppercase;
			color: ${component.color.white};
			white-space: pre-line;
			line-height: 60px;

			${() => component.breakpoint.lg.mixin('font-size: 34px; line-height: 40px;')}
			${() => component.breakpoint.md.mixin('font-size: 30px; line-height: 40px;')}
			${() => component.breakpoint.sm.mixin('font-size: 22px; line-height: 30px;')}
			${() => component.breakpoint.xs.mixin('font-size: 24px; line-height: 30px;')}
			${() => component.breakpoint.xxs.mixin('font-size: 22px; line-height: 30px;')}
		}
	}
`;

export default PreFooter;
