import { useCallback, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import Slider from 'react-slick';

import FontAwesome from '@component/shared/font-awesome';

import Subtitle from '@page/home/components/hero/subtitle';
import HeroButton from '@page/home/components/hero/button';
import Title from '@page/home/components/hero/title';

import useBreakpoint from '@util/hooks/useBreakpoint';
import useTheme from '@util/hooks/useTheme';

import component from '@config/component';

// Import Data
import useData from '@data/useData';

import { gsap } from 'gsap';
import { SplitText } from 'gsap-trial/SplitText';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(SplitText);

const HeroUnStyled = ({ className }) => {
	const [activeSlide, setActiveSlide] = useState(0);
	const [loading, setLoading] = useState(false);
	let sliderRef = useRef(null);

	const { isPreloaderEnabled } = useTheme();
	const { isUpward, isBelowOrEqual } = useBreakpoint();
	const { sliders } = useData();

	useGSAP(() => {
		gsap.set('.hero-title, .hero-subtitle', { perspective: 200 });

		const title = new SplitText('.hero-title', { type: 'lines' });
		const subtitle = new SplitText('.hero-subtitle', { type: 'lines' });
		const button = document.querySelector('.hero-button');

		const timeline = gsap.timeline();
		timeline.delay(isPreloaderEnabled ? 5.5 : 2).fromTo(
			[title.lines, subtitle.lines, button],
			{ opacity: 0, y: 80 },
			{
				opacity: 1,
				y: 0,
				duration: 0.5,
				stagger: -0.1
			}
		);
	}, []);

	const changeSlide = useCallback((prev, next) => {
		setLoading(true);

		gsap.set('.hero-title, .hero-subtitle', { perspective: 200 });

		const title = new SplitText('.hero-title', { type: 'lines' });
		const subtitle = new SplitText('.hero-subtitle', { type: 'lines' });
		const button = document.querySelector('.hero-button');

		const timeline = gsap.timeline();
		timeline
			.to([title.lines, subtitle.lines, button], {
				opacity: 0,
				y: 80,
				duration: 0.5,
				stagger: -0.1
			})
			.add(() => setActiveSlide(next))
			.to('.hero-title, .hero-subtitle', { opacity: 0 }, '-=0.5')
			.add(() => changeSlideEnd(prev, next), '+=0.5');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changeSlideEnd = useCallback((prev, next) => {
		gsap.set('.hero-title, .hero-subtitle', { perspective: 200 });

		const title = new SplitText('.hero-title', { type: 'lines' });
		const subtitle = new SplitText('.hero-subtitle', { type: 'lines' });
		const button = document.querySelector('.hero-button');

		const timeline = gsap.timeline();
		timeline
			.to('.hero-title, .hero-subtitle', { opacity: 1 }, '-=0.5')
			.fromTo(
				[title.lines, subtitle.lines, button],
				{ opacity: 0, y: 80 },
				{
					opacity: 1,
					y: 0,
					duration: 0.5,
					stagger: -0.1
				}
			)
			.add(() => setLoading(false));
	}, []);

	return (
		<div className={className}>
			<Slider
				dots={false}
				infinite={true}
				slidesToShow={1}
				slidesToScroll={1}
				arrows={false}
				speed={3000}
				autoplay={true}
				autoplaySpeed={10000}
				ref={(slider) => {
					sliderRef = slider;
				}}
				beforeChange={changeSlide}
			>
				{sliders.map((slide, index) => {
					return (
						<img
							key={index}
							className={'hero-background'}
							src={component.image(slide.background, ['f_webp', 'q_auto', 'w_1920', 'h_1080', 'c_fill'])}
							alt={slide.title}
						/>
					);
				})}
			</Slider>

			<div className="hero-overlay">
				<Container>
					{sliders[activeSlide].hasOwnProperty('title') && <Title text={sliders[activeSlide].title} />}
					{isBelowOrEqual('md') && sliders[activeSlide].hasOwnProperty('subtitle') && (
						<Subtitle text={sliders[activeSlide].subtitle} />
					)}
					{isBelowOrEqual('md') && sliders[activeSlide].hasOwnProperty('button') && (
						<HeroButton border={false} button={sliders[activeSlide].button} />
					)}
				</Container>
				<Container>
					<div className="hero-contents">
						{isUpward('md') && (
							<div className="item text-start">
								{sliders[activeSlide].hasOwnProperty('subtitle') && (
									<Subtitle text={sliders[activeSlide].subtitle} />
								)}
								{sliders[activeSlide].hasOwnProperty('button') && (
									<HeroButton button={sliders[activeSlide].button} border={false} />
								)}
							</div>
						)}
						<div className="item align-self-end text-end">
							<button className={'arrow me-3'} disabled={loading} onClick={() => sliderRef.slickPrev()}>
								<FontAwesome icon={'arrow-left'} />
							</button>
							<button className={'arrow'} disabled={loading} onClick={() => sliderRef.slickNext()}>
								<FontAwesome icon={'arrow-right'} />
							</button>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};

const Hero = styled(HeroUnStyled)`
	position: relative;
	background: ${component.color.white};
	height: 100vh;
	width: 100%;
	overflow: hidden;

	.slick {
		&-arrow {
			display: none !important;
		}
	}

	.hero {
		position: relative;

		&-background {
			height: 100vh;
			width: 100%;
			overflow: hidden;
			object-fit: cover;

			${() => component.breakpoint.md.mixin('width: 100vw;')}
			${() => component.breakpoint.sm.mixin('width: 100vw;')}
            ${() => component.breakpoint.xs.mixin('width: 100vw;')}
            ${() => component.breakpoint.xxs.mixin('width: 100vw;')}
		}

		&-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: ${hexToRgba(component.color.primary, 0.6)};

			& .container {
				&:nth-child(1) {
					height: calc(100vh - 30%);
					display: flex;
					align-items: center;

					${() =>
						component.breakpoint.md.mixin(
							'height: calc(100vh - 30%); flex-direction: column; justify-content:center;'
						)}
					${() =>
						component.breakpoint.sm.mixin(
							'height: calc(100vh - 20%); flex-direction: column; justify-content:center;'
						)}
                    ${() =>
						component.breakpoint.xs.mixin(
							'height: calc(100vh - 20%); flex-direction: column; justify-content:center;'
						)}
                    ${() =>
						component.breakpoint.xxs.mixin(
							'height: calc(100vh - 20%); flex-direction: column; justify-content:center;'
						)}
				}

				&:nth-child(3) {
					height: calc(100vh - 70%);
					display: flex;
					align-items: center;

					${() => component.breakpoint.md.mixin('height: calc(100vh - 70%);')}
					${() => component.breakpoint.sm.mixin('height: calc(100vh - 80%);')}
                    ${() => component.breakpoint.xs.mixin('height: calc(100vh - 80%);')}
                    ${() => component.breakpoint.xxs.mixin('height: calc(100vh - 80%);')}
				}
			}

			hr {
				margin: 0;
				width: 100%;
				border: 1px solid ${component.color.white};
			}
		}

		&-contents {
			padding: 40px 0;
			display: flex;
			justify-content: space-between;
			width: 100%;

			${() => component.breakpoint.md.mixin('justify-content: center;')}
			${() => component.breakpoint.sm.mixin('justify-content: center;')}
            ${() => component.breakpoint.xs.mixin('justify-content: center;')}
            ${() => component.breakpoint.xxs.mixin('justify-content: center;')}
			
            .item {
				font-family: ${component.font.family.poppins};
				font-size: 20px;
				color: ${component.color.white};
				width: 50%;

				${() => component.breakpoint.md.mixin('width: unset;')}
				${() => component.breakpoint.sm.mixin('width: unset;')}
                ${() => component.breakpoint.xs.mixin('width: unset;')}
                ${() => component.breakpoint.xxs.mixin('width: unset;')}
				
                & .arrow {
					padding: 11px 16px;
					border: 1px solid ${component.color.white} !important;
					border-radius: 50%;
					color: ${component.color.white};
					background: transparent;
					transition: ease-in-out;
					transition-duration: 200ms;

					&:hover {
						background: ${hexToRgba(component.color.white, 0.3)};
						transform: scale(1.1);
						transition: ease-in-out;
						transition-duration: 200ms;
					}

					&:active {
						background: ${hexToRgba(component.color.white, 1)};
						transform: scale(1.1);
						transition: ease-in-out;
						transition-duration: 200ms;
						color: ${component.color.black};
					}
				}
			}
		}
	}
`;

export default Hero;
