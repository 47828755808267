import { useJsApiLoader } from '@react-google-maps/api';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useEffect } from 'react';

import FloatingHeader from '@component/layout/main/floating-header';
import JobDetails from '@component/layout/main/job-details';
import PreLoader from '@component/layout/main/preloader';
import Loader from '@component/layout/main/loader';
import Footer from '@component/layout/main/footer';
import Header from '@component/layout/main/header';
import RightBar from '@component/layout/main/bar';
import Menu from '@component/layout/main/menu';

import FontAwesome from '@component/shared/font-awesome';

import useBreakpoint from '@util/hooks/useBreakpoint';
import useTheme from '@util/hooks/useTheme';
import component from '@config/component';
import useData from '@data/useData';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap-trial/ScrollSmoother';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
gsap.config({ trialWarn: false, nullTargetWarn: false });

const MainLayoutUnStyled = ({ className }) => {
	const { isPreloaderEnabled, isLoaderEnabled, showPreloader, options } = useTheme();
	const { socials } = useData();
	const { /*size,*/ isUpward, isBelowOrEqual } = useBreakpoint();

	const location = useLocation();

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDEZAYYrMPQxQ_A2Y3XWi84HI6-E-oYkYY',
		version: 'weekly'
	});

	useEffect(() => {
		showPreloader();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const main = document.querySelector('.main');
		main.style.width = isUpward('md') ? 'calc(100% - 114px)' : '100%';

		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key]);

	useGSAP(() => {
		ScrollSmoother.create({
			autoResize: true,
			wrapper: '.main',
			content: '.main-content',
			smooth: 1.5,
			effects: true
		});
	}, []);

	return (
		<div className={className}>
			{isPreloaderEnabled && <PreLoader />}
			{isLoaderEnabled && <Loader />}

			{isBelowOrEqual('md') && <FloatingHeader />}

			<Menu />
			<div className={'main'}>
				<div className="main-content">
					<Header />
					{isLoaded && <Outlet />}
					<Footer />
				</div>
			</div>
			{options.hasOwnProperty('slug') && <JobDetails />}

			{isUpward('md') && <RightBar />}

			{/*<div className="breakpoint">
				<h1>{size}</h1>
			</div>*/}

			<div className="buttons">
				<button onClick={() => window.open(socials.messenger, '_blank')}>
					<FontAwesome type={'brands'} icon={'facebook-messenger'} />
				</button>
				<button onClick={() => window.open(socials.whatsapp, '_blank')}>
					<FontAwesome type={'brands'} icon={'whatsapp'} />
				</button>
				<button onClick={() => window.scrollTo(0, 0, { behavior: 'smooth' })}>
					<FontAwesome type={'regular'} icon={'arrow-up-to-line'} />
				</button>
			</div>
		</div>
	);
};

const MainLayout = styled(MainLayoutUnStyled)`
	.breakpoint {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		font-size: 12px;
		padding: 15px;
		color: ${component.color.white};
		background: ${component.color.primary};
		text-transform: uppercase;
	}

	.buttons {
		position: fixed;
		bottom: 20px;
		left: 20px;

		button,
		a {
			display: block;
			height: 40px;
			width: 40px;
			background: ${component.color.white};
			color: ${component.color.primary};
			margin-bottom: 10px;
			border-radius: 50%;
			border: 1px solid ${component.color.primary};
			font-size: 20px;

			&:not(:last-child) i {
				animation: blink-animation 5s infinite;
			}
		}
	}

	@keyframes blink-animation {
		0% {
			opacity: 0;
			scale: 0;
		}

		5% {
			opacity: 1;
			scale: 1;
		}

		90% {
			opacity: 1;
			scale: 1;
		}

		100% {
			opacity: 0;
			scale: 0;
		}
	}
`;

export default MainLayout;
