import styled from 'styled-components';

import component from '@config/component';
import useTheme from '@util/hooks/useTheme';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';

const PreLoaderUnStyled = ({ className, loaded }) => {
	const { hidePreloader } = useTheme();

	useGSAP(() => {
		const timeline = gsap.timeline({});

		timeline
			.fromTo(
				'.logo-frame',
				{
					opacity: 0,
					y: -200
				},
				{
					opacity: 1,
					y: 0,
					duration: 1,
					ease: 'expo.inOut'
				}
			)
			.fromTo(
				'.logo-window:nth-child(n+4)',
				{
					opacity: 0,
					x: -200
				},
				{
					opacity: 1,
					x: 0,
					delay: -0.5,
					duration: 1,
					ease: 'expo.inOut'
				}
			)
			.fromTo(
				'.logo-window:nth-child(-n+3)',
				{
					opacity: 0,
					x: -200
				},
				{
					opacity: 1,
					x: 0,
					delay: -0.5,
					duration: 1,
					ease: 'expo.inOut'
				}
			)
			.to('.logo-window:nth-child(n+4)', {
				opacity: 0,
				x: 200,
				delay: 1,
				duration: 1,
				ease: 'expo.inOut'
			})
			.to('.logo-window:nth-child(-n+3)', {
				opacity: 0,
				x: 200,
				delay: -0.5,
				duration: 1,
				ease: 'expo.inOut'
			})
			.to('.logo-frame', {
				opacity: 0,
				y: -200,
				delay: -0.5,
				duration: 1,
				ease: 'expo.inOut'
			})
			.to('.comp-main', {
				height: '0',
				y: -10,
				delay: -0.2,
				duration: 1.5,
				ease: 'power1.inOut'
			})
			.to('.comp-alt', {
				height: '0',
				y: -10,
				duration: 1.5,
				delay: -1.45,
				ease: 'power3.inOut',
				onComplete: hidePreloader
			});
	});

	return (
		<div className={className}>
			<div className={'comp-main'}>
				<svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						className={'logo-frame'}
						fill={'white'}
						d="M119.56,34.63L80.25,3.35H5.4l39.31,31.32c1.95,1.68,3.04,4.13,3.04,6.71v45.29c0,2.58-1.12,5.02-3.04,6.71L5.4,124.65h74.85l39.31-31.28c1.95-1.68,3.04-4.13,3.04-6.71v-45.32c.03-2.58-1.09-5.02-3.04-6.71Z"
					/>
					<path
						className={'logo-window'}
						fill={'#020B24'}
						d="M81.04,60.27h-17.38c-.3,0-.56-.23-.56-.56v-13.51c0-.3.23-.56.56-.56h17.38c.3,0,.56.23.56.56v13.51c-.03.33-.26.56-.56.56Z"
					/>
					<path
						className={'logo-window'}
						fill={'#020B24'}
						d="M81.04,82.27h-17.38c-.3,0-.56-.23-.56-.56v-13.51c0-.3.23-.56.56-.56h17.38c.3,0,.56.23.56.56v13.51c-.03.33-.26.56-.56.56Z"
					/>
					<path
						className={'logo-window'}
						fill={'#020B24'}
						d="M106.71,60.27h-17.38c-.3,0-.56-.23-.56-.56v-13.51c0-.3.23-.56.56-.56h17.38c.3,0,.56.23.56.56v13.51c0,.33-.26.56-.56.56Z"
					/>
					<path
						className={'logo-window'}
						fill={'#020B24'}
						d="M106.71,82.27h-17.38c-.3,0-.56-.23-.56-.56v-13.51c0-.3.23-.56.56-.56h17.38c.3,0,.56.23.56.56v13.51c0,.33-.26.56-.56.56Z"
					/>
				</svg>
			</div>
			<div className={'comp-alt'} />
		</div>
	);
};

const PreLoader = styled(PreLoaderUnStyled)`
	top: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 1001;
	overflow: hidden;

	.comp {
		&-main {
			height: 100vh;
			width: 100vw;
			background: ${component.color.primary};
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		&-alt {
			height: 100vh;
			width: 100vw;
			background: ${component.color.white};
		}
	}

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		& img {
			position: absolute;
			bottom: 0;
		}
	}

	.loader {
		margin-top: 80px;
		margin-bottom: 0;
		width: 100%;
		height: 2px;
		background: ${component.color.white};
	}
`;

export default PreLoader;
