import { Container } from 'react-bootstrap';
import { styled } from 'styled-components';
import classNames from 'classnames';
import hexToRgba from 'hex-to-rgba';
import Select from 'react-select';
import { useEffect, useState } from 'react';

import Button from '@component/shared/button';

import component from '@config/component';

import { gsap } from 'gsap';
import { SplitText } from 'gsap-trial/SplitText';
import select from '@config/select';
import useData from '@data/useData';
import axios from 'axios';

gsap.registerPlugin(SplitText);

const BuyerFormUnStyled = ({ className }) => {
	const [formData, setFormData] = useState({
		area: '',
		size: '',
		name: '',
		address: '',
		contact: '',
		email: ''
	});
	const [errors, setErrors] = useState({});
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const classes = classNames(className, 'buyer-form');
	const { getProjectSizes, getProjectLocations } = useData();

	useEffect(() => {
		setTimeout(() => {
			if (message) setMessage('');
		}, 5000);
	}, [message]);

	const submitForm = (e) => {
		e.preventDefault();
		setLoading(true);
		setErrors({});
		axios
			.post(component.makeApiEndpoint('contact/buyer'), formData)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					setMessage(data.message);
					resetValues();
				}
			})
			.catch((err) => {
				const { data } = err.response;
				const errs = {};
				if (data.hasOwnProperty('errors') && data.errors.length > 0)
					data.errors.forEach((error) => (errs[error.path] = error.message));
				setErrors(errs);
			})
			.finally(() => setLoading(false));
	};

	const resetValues = () => {
		setFormData({
			area: '',
			size: '',
			name: '',
			address: '',
			contact: '',
			email: ''
		});
	};

	return (
		<div className={classes}>
			<Container className={'py-5'}>
				<h1 className={'form-title'}>Let's invest for future</h1>
				<form onSubmit={submitForm}>
					<div className="form-control">
						<label>Area*</label>
						<Select
							styles={select}
							isClearable={true}
							value={formData.area ? { label: formData.area, value: formData.area } : null}
							options={getProjectLocations().map((item) => ({ label: item, value: item }))}
							onChange={(option) => setFormData({ ...formData, area: option.value })}
						/>
						{errors.hasOwnProperty('area') && <div className="form-control-error">{errors.area}</div>}
					</div>

					<div className="form-control">
						<label>Size*</label>
						<Select
							styles={select}
							isClearable={true}
							value={formData.size ? { label: formData.size, value: formData.size } : null}
							options={getProjectSizes().map((item) => ({ label: `${item} sft`, value: `${item} sft` }))}
							onChange={(option) => setFormData({ ...formData, size: option.value })}
						/>
						{errors.hasOwnProperty('size') && <div className="form-control-error">{errors.size}</div>}
					</div>

					<div className="form-control">
						<label htmlFor="name">Name*</label>
						<input
							type="text"
							value={formData.name}
							onChange={(e) => setFormData({ ...formData, name: e.target.value })}
						/>
						{errors.hasOwnProperty('name') && <div className="form-control-error">{errors.name}</div>}
					</div>

					<div className="form-control">
						<label htmlFor="address">Address*</label>
						<input
							type="text"
							value={formData.address}
							onChange={(e) => setFormData({ ...formData, address: e.target.value })}
						/>
						{errors.hasOwnProperty('address') && <div className="form-control-error">{errors.address}</div>}
					</div>

					<div className="form-control">
						<label htmlFor="contact">Contact Number*</label>
						<input
							type="text"
							value={formData.contact}
							onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
						/>
						{errors.hasOwnProperty('contact') && <div className="form-control-error">{errors.contact}</div>}
					</div>

					<div className="form-control">
						<label htmlFor="email">Email*</label>
						<input
							type="text"
							value={formData.email}
							onChange={(e) => setFormData({ ...formData, email: e.target.value })}
						/>
						{errors.hasOwnProperty('email') && <div className="form-control-error">{errors.email}</div>}
					</div>

					<div className="submitter d-flex justify-content-between align-items-center">
						{message ? <div className="form-control-success">{message}</div> : <span></span>}
						<span>
							<Button
								type={'button'}
								className={'me-3'}
								icon={{ position: 'end', icon: 'rotate', className: 'ms-2' }}
								onClick={() => resetValues()}
							>
								Reset
							</Button>
							<Button
								type={'submit'}
								active={true}
								loading={loading}
								icon={{ position: 'end', icon: 'arrow-right', className: 'ms-2' }}
							>
								Submit
							</Button>
						</span>
					</div>
				</form>
			</Container>
		</div>
	);
};

const BuyerForm = styled(BuyerFormUnStyled)`
	.form {
		&-title {
			font-family: ${component.font.family.reckless};
			font-size: 42px;
			margin-bottom: 30px;
		}

		&-control {
			border: none !important;
			padding: 0;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			label {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[500]};
				margin-bottom: 5px;
			}

			input {
				padding: 8px 0;
				border: 0;
				border-bottom: 1px solid ${hexToRgba(component.color.black, 0.4)};
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: ${component.color.black};

				&::placeholder {
					font-family: ${component.font.family.poppins};
					font-weight: ${component.font.weight[300]};
					color: ${hexToRgba(component.color.black, 0.4)};
				}

				&:hover,
				&:focus,
				&:active {
					outline: none;
					box-shadow: none;
				}
			}

			&-error {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: #f60b0b;
				font-size: 14px;
				margin-top: 10px;
			}

			&-success {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: #42dd00;
				font-size: 14px;
			}
		}
	}
`;

export default BuyerForm;
