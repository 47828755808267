import { createAction, createReducer } from 'redux-act';

const initTheme = {
	isPreloaderEnabled: true,
	isLoaderEnabled: false,
	options: {},
	isMenuOpen: false,
	isJobOpen: false
};

export const openMenu = createAction('OPEN_MENU');
export const closeMenu = createAction('CLOSE_MENU');
export const toggleMenu = createAction('TOGGLE_MENU');

export const openJob = createAction('OPEN_JOB', (args) => args);
export const closeJob = createAction('CLOSE_JOB');

export const showPreloader = createAction('SHOW_PRELOADER');
export const hidePreloader = createAction('HIDE_PRELOADER');

export const showLoader = createAction('SHOW_LOADER', (args) => args);
export const hideLoader = createAction('HIDE_LOADER');

const theme = createReducer(
	{
		[openMenu]: (state) => ({
			...state,
			isMenuOpen: true
		}),
		[closeMenu]: (state) => ({
			...state,
			isMenuOpen: false
		}),
		[toggleMenu]: (state) => ({
			...state,
			isMenuOpen: !state.isMenuOpen
		}),
		[openJob]: (state, options) => ({
			...state,
			isJobOpen: true,
			options: options
		}),
		[closeJob]: (state) => ({
			...state,
			isJobOpen: false,
			options: {}
		}),
		[showPreloader]: (state) => ({
			...state,
			isPreloaderEnabled: true
		}),
		[hidePreloader]: (state) => ({
			...state,
			isPreloaderEnabled: false,
			options: {}
		}),
		[showLoader]: (state, options) => ({
			...state,
			isLoaderEnabled: true,
			options: options
		}),
		[hideLoader]: (state) => ({
			...state,
			isLoaderEnabled: false,
			options: {}
		})
	},
	initTheme
);

export default theme;
