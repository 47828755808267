import { Markup } from 'react-render-markup';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import component from '@config/component';
import useData from '@data/useData';

import { gsap } from 'gsap';
import { useGSAP } from '@gsap/react';
import classNames from 'classnames';
import FontAwesome from '@component/shared/font-awesome';
import useTheme from '@util/hooks/useTheme';
import useBreakpoint from '@util/hooks/useBreakpoint';

const JobDetailsUnStyled = ({ className }) => {
	const classes = classNames(className, 'job-details');

	const { closeJob, isJobOpen, options } = useTheme();
	const { isUpward } = useBreakpoint();
	const { getJob } = useData();
	const job = getJob(options?.slug);

	useGSAP(() => {
		if (isJobOpen) {
			gsap.fromTo('.job-details', { x: '100%' }, { x: '0', duration: 1 });
		}
	}, [isJobOpen]);

	const close = () => {
		gsap.to('.job-details', { x: '100%', duration: 1, onComplete: () => closeJob() });
	};

	return (
		<div className={classes} style={{ width: isUpward('md') ? 'calc(100% - 114px)' : '100%' }}>
			<button className={'job-details-close'} onClick={() => close()}>
				<FontAwesome icon={'times'} />
			</button>
			<Container className={'py-5 job-details-content'}>
				<h1 className={'job-details-content-title mb-4'}>{job.title}</h1>
				<div className={'point'}>
					<FontAwesome type={'regular'} icon={'location-dot'} />
					<p>{job.location}</p>
				</div>
				<div className={'point'}>
					<FontAwesome type={'regular'} icon={'clock-desk'} />
					<p>{job.nature}</p>
				</div>
				<div className={'point'}>
					<FontAwesome type={'regular'} icon={'sack-dollar'} />
					<p>{job.salary}</p>
				</div>
				<div className={'point'}>
					<FontAwesome type={'regular'} icon={'user'} />
					<p>{job.vacancy} (Vacancy)</p>
				</div>
				<div className={'point'}>
					<FontAwesome type={'regular'} icon={'flask-round-potion'} />
					<p>{job.experience} Experience</p>
				</div>
				<div className={'mt-4'}>
					<Markup markup={job.description} />
				</div>
			</Container>
		</div>
	);
};

const JobDetails = styled(JobDetailsUnStyled)`
	position: fixed;
	height: 100vh;
	background-color: ${component.color.white};
	top: 0;
	z-index: 995;
	overflow: scroll;
	background-image: url('/assets/images/pattern-1-2.svg');
	background-size: 100%;
	background-position: center center;

	${() => component.breakpoint.md.mixin('width: 100%;')}
	${() => component.breakpoint.sm.mixin('width: 100%; background-size: 150%;')}
    ${() => component.breakpoint.xs.mixin('width: 100%; background-size: 200%;')}
    ${() => component.breakpoint.xxs.mixin('width: 100%; background-size: 250%;')}

    .job-details {
		&-content {
			&-title {
				font-family: ${component.font.family.reckless};
				font-weight: ${component.font.weight[700]} !important;
			}

			& .point {
				display: flex;
				align-items: center;
				margin-bottom: 6px;

				& i {
					height: 20px;
					width: 20px;
					margin-right: 2px;
				}

				& p {
					font-family: ${component.font.family.poppins};
					font-weight: ${component.font.weight[400]};
					font-size: 14px;
					margin-bottom: 0;
					overflow: hidden;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
				}
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-family: ${component.font.family.reckless};
				font-weight: ${component.font.weight[600]};
			}

			h1 {
				font-size: 42px;
			}

			& h2 {
				font-size: 36px;
			}

			h3 {
				font-size: 30px;
			}

			h4 {
				font-size: 24px;
			}

			h5 {
				font-size: 18px;
			}

			h6 {
				font-size: 12px;
			}

			p {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				line-height: 26px;
				font-size: 14px;
				text-align: justify;
			}

			img {
				width: 100%;
				object-fit: cover;
			}

			ul {
				list-style: none;
				padding-left: 0;

				li {
					font-family: ${component.font.family.poppins};
					font-weight: ${component.font.weight[300]};
					line-height: 32px;
					font-size: 14px;
					text-align: justify;

					&:before {
						content: '';
						font-family: 'Font Awesome 6 Pro', serif;
						font-weight: 900;
						padding: 0 8px 0 10px;
						font-size: 16px;
					}
				}
			}
		}

		&-close {
			position: absolute;
			border: none !important;
			background: none;
			top: 20px;
			right: 40px;
			font-size: 24px;
		}
	}
`;

export default JobDetails;
