import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import router from '@config/rotuer';

import '@asset/styles/scss/global.scss';
import 'slick-carousel/slick/slick.css';

import { persist, store } from '@util/store';

function App() {
	return (
		<div className="app">
			<Provider store={store}>
				<PersistGate persistor={persist}>
					<RouterProvider router={router} />
				</PersistGate>
			</Provider>
		</div>
	);
}

export default App;
