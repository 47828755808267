const cloudinaryId = 'dh9piu1wm';
const cloudinaryUrl = `https://res.cloudinary.com/${cloudinaryId}/image/upload/`;

const image = (path, configs = []) => {
	const mappedConfigs = configs.join(',');

	if (configs.length > 0) return `${cloudinaryUrl}${mappedConfigs}/${path}`;
	else return `${cloudinaryUrl}${path}`;
};

const makeApiEndpoint = (path) => {
	const host = 'https://api.domicile.com.bd';

	return `${host}/${path}`;
};

const component = {
	font: {
		family: {
			poppins: '"Poppins", sans-serif',
			reckless: 'Reckless Neue',
			lato: '"Lato", sans-serif',
			inter: '"Inter", sans-serif'
		},
		style: {
			normal: 'normal',
			italic: 'italic'
		},
		weight: {
			100: '100',
			200: '200',
			300: '300',
			400: '400',
			500: '500',
			600: '600',
			700: '700',
			800: '800',
			900: '900'
		}
	},
	color: {
		primary: '#020B24',
		secondary: '#585b60',
		white: '#ffffff',
		black: '#000000'
	},
	breakpoint: {
		xxs: {
			start: 200,
			end: 397,
			mixin: (styles) => `
			@media only screen and (min-width: 200px) and (max-width: 397px) {
				${styles}
			}
		`
		},
		xs: {
			start: 398,
			end: 575,
			mixin: (styles) => `
			@media only screen and (min-width: 398px) and (max-width: 575px) {
				${styles}
			}
		`
		},
		sm: {
			start: 576,
			end: 767,
			mixin: (styles) => `
			@media only screen and (min-width: 576px) and (max-width: 767px) {
				${styles}
			}
		`
		},
		md: {
			start: 768,
			end: 991,
			mixin: (styles) => `
			@media only screen and (min-width: 768px) and (max-width: 991px) {
				${styles}
			}
		`
		},
		lg: {
			start: 992,
			end: 1199,
			mixin: (styles) => `
			@media only screen and (min-width: 992px) and (max-width: 1199px) {
				${styles}
			}
		`
		},
		xl: {
			start: 1200,
			end: 1399,
			mixin: (styles) => `
			@media only screen and (min-width: 1200px) and (max-width: 1399px) {
				${styles}
			}
		`
		},
		xxl: {
			start: 1400,
			end: 1920,
			mixin: (styles) => `
			@media only screen and (min-width: 1400px) and (max-width: 1920px){
				${styles}
			}
		`
		}
	},
	image,
	makeApiEndpoint
};

export default component;
