import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';

import component from '@config/component';

import { gsap } from 'gsap';
import { SplitText } from 'gsap-trial/SplitText';
import { useGSAP } from '@gsap/react';
import useBreakpoint from '@util/hooks/useBreakpoint';

gsap.registerPlugin(SplitText);

const AboutUnStyled = ({ className }) => {
	const classes = classNames(className, 'about');
	const { isUpward } = useBreakpoint();

	useGSAP(() => {
		const content = new SplitText('.about .content p', {
			type: 'words'
		});
		gsap.set('.about .content p', { perspective: 10 });
		gsap.fromTo(
			content.words,
			{
				opacity: 0,
				y: 40
			},
			{
				opacity: 1,
				y: 0,
				ease: 'power1.inOut',
				delay: 0.5,
				stagger: 0.02,
				scrollTrigger: {
					trigger: '.about',
					start: 'top 50%',
					toggleActions: 'play pause resume reset'
				}
			}
		);

		isUpward('md') &&
			gsap.fromTo(
				'.about img',
				{
					x: -600
				},
				{
					x: 0,
					duration: 0.3,
					stagger: 0.1,
					scrollTrigger: {
						trigger: '.about',
						start: 'top 100%',
						end: 'bottom 75%',
						toggleActions: 'play pause resume reset',
						scrub: true
					}
				}
			);

		isUpward('md') &&
			gsap.fromTo(
				'.about .content',
				{
					x: 600
				},
				{
					x: 0,
					duration: 0.3,
					stagger: 0.1,
					scrollTrigger: {
						trigger: '.about',
						start: 'top 100%',
						end: 'bottom 75%',
						toggleActions: 'play pause resume reset',
						scrub: true
					}
				}
			);
	}, []);

	return (
		<div className={classes}>
			<Container>
				<img
					src={component.image('749bf8af209d847b9ba8bf7664ec170c_m6r7q0', [
						'f_webp',
						'q_auto',
						'w_900',
						'h_600',
						'c_fill'
					])}
					alt={'Domicile Home Interior'}
				/>
				<div className="content">
					<p>
						At Domicile, we believe in building more than just homes; we create spaces that inspire,
						nurture, and endure. Each project reflects our unwavering commitment to quality, sustainability,
						and innovation. With every brick we lay, we promise trust, excellence, and a future where your
						dreams find their foundation.
					</p>
				</div>
			</Container>
		</div>
	);
};

const About = styled(AboutUnStyled)`
	padding: 100px 0 0 0;
	width: 100%;
	
    ${() => component.breakpoint.xs.mixin('padding: 30px 0 0 0;')}
    ${() => component.breakpoint.xxs.mixin('padding: 20px 0 0 0;')}

	& .container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		${() => component.breakpoint.md.mixin('flex-direction: column-reverse; justify-content: center;')}
		${() => component.breakpoint.sm.mixin('flex-direction: column-reverse; justify-content: center;')}
		${() => component.breakpoint.xs.mixin('flex-direction: column-reverse; justify-content: center;')}
		${() => component.breakpoint.xxs.mixin('flex-direction: column-reverse; justify-content: center;')}

		& .content {
			align-self: center;
			padding: 80px;
			background-color: ${component.color.secondary};
			margin-left: -25%;
			width: 100%;
			height: max-content;
			position: relative;
			border-radius: 6px;

			${() => component.breakpoint.xl.mixin('margin-left: -50%;')}
			${() => component.breakpoint.lg.mixin('margin-left: -75%;')}
			${() => component.breakpoint.md.mixin('margin-left: 0;')}
			${() => component.breakpoint.sm.mixin('margin-left: 0; padding: 80px 40px;')}
			${() => component.breakpoint.xs.mixin('margin-left: 0; padding: 80px 40px;')}
			${() => component.breakpoint.xxs.mixin('margin-left: 0; padding: 80px 40px;')}

			p {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[200]};
				color: ${component.color.white};
				font-size: 20px;
				line-height: 28px;
				text-align: justify;
				position: relative;
				margin-bottom: 0;
			}
		}
	}

	& img {
		width: 100%;
		height: 600px;
		object-fit: cover;
		border-radius: 6px;

		${() => component.breakpoint.md.mixin('display: none; visibility: hidden;')}
		${() => component.breakpoint.sm.mixin('display: none; visibility: hidden;')}
        ${() => component.breakpoint.xs.mixin('display: none; visibility: hidden;')}
        ${() => component.breakpoint.xxs.mixin('display: none; visibility: hidden;')}
	}
`;

export default About;
