import { useEffect, useState } from 'react';
import _ from 'lodash';

const breakpoints = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
const widths = [398, 575, 767, 991, 1199, 1399, 1400];

const useBreakpoint = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);

	const setDimension = () => {
		const { innerWidth, innerHeight } = window;
		setWidth(innerWidth);
		setHeight(innerHeight);
	};

	useEffect(() => {
		window.addEventListener('resize', setDimension);
		return () => window.removeEventListener('resize', setDimension);
	}, []);

	const size = () => {
		const index = widths.findIndex((w) => width < w);
		return breakpoints[index >= 0 ? index : breakpoints.length - 1];
	};

	const is = (point) => {
		if (_.isString(point)) return size() === point;
		if (_.isArray(point)) return point.includes(size());
		return false;
	};

	const isBelow = (point) => breakpoints.indexOf(point) > breakpoints.indexOf(size());
	const isBelowOrEqual = (point) => breakpoints.indexOf(point) >= breakpoints.indexOf(size());
	const isUpward = (point) => breakpoints.indexOf(point) < breakpoints.indexOf(size());
	const isUpwardOrEqual = (point) => breakpoints.indexOf(point) <= breakpoints.indexOf(size());

	return {
		width,
		height,
		size: size(),
		is,
		isBelow,
		isBelowOrEqual,
		isUpward,
		isUpwardOrEqual
	};
};

export default useBreakpoint;
