import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import classNames from 'classnames';
import hexToRgba from 'hex-to-rgba';

import useTheme from '@util/hooks/useTheme';
import component from '@config/component';

const QuerySelectorUnStyled = ({ className }) => {
	const classes = classNames(className, 'query-selector');

	const navigate = useNavigate();
	const { showLoader } = useTheme();

	return (
		<div className={classes}>
			<Container className={'py-5'}>
				<h1 className={'query-title mb-5'}>What Best Describes you ?</h1>
				<Row className={'g-5 image-boxes'}>
					<Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className={'landowner'}>
						<div
							className="image-box"
							onClick={() => showLoader({ navigate: () => navigate('/query/landowner') })}
						>
							<img
								src={component.image('62a82fc2715e150721ac227a0bd6db1a_xurpgr', [
									'f_webp',
									'q_auto',
									'w_600',
									'h_400',
									'c_fill'
								])}
								alt="Landowner"
							/>
							<div className="overlay">
								<div className="tag-title">
									<h2>Landowner</h2>
								</div>
							</div>
						</div>
					</Col>
					<Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className={'buyer'}>
						<div
							className="image-box"
							onClick={() => showLoader({ navigate: () => navigate('/query/buyer') })}
						>
							<img
								src={component.image('65199a27dfbb0b24b7bcfd1db8af9568_kny1ul', [
									'f_webp',
									'q_auto',
									'w_600',
									'h_400',
									'c_fill'
								])}
								alt="Buyer"
							/>
							<div className="overlay">
								<div className="tag-title">
									<h2>Buyer</h2>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const QuerySelector = styled(QuerySelectorUnStyled)`
	.image {
		&-box {
			cursor: pointer;
			transition-duration: 500ms;
			position: relative;
			overflow: hidden;
			border-radius: 6px;

			& img {
				height: 400px;
				width: 100%;
				object-fit: cover;
				transition-duration: 500ms;
			}

			& .tag-title {
				padding: 20px;
				min-width: 300px;
				background-color: ${hexToRgba(component.color.primary, 0.8)};
				border-radius: 6px;
				position: absolute;
				bottom: 20px;
				left: 20px;
				text-align: center;
				transition-duration: 500ms;

				& h2 {
					font-family: ${component.font.family.reckless};
					font-weight: ${component.font.weight[800]};
					font-size: 32px;
					color: ${component.color.white};
					text-transform: uppercase;
				}
			}

			& .overlay {
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				border-radius: 6px;
			}

			&:hover {
				box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

				img {
					scale: 1.04;
				}

				.tag-title {
					scale: 1.05;
				}
			}
		}
	}
`;

export default QuerySelector;
