import { styled } from 'styled-components';
import classNames from 'classnames';

import Breadcrumb from '@component/layout/main/breadcrumb';

import useData from '@data/useData';

const TeamUnStyled = ({ className }) => {
	const classes = classNames(className, 'team');

	const { getPage } = useData();
	const { breadcrumb } = getPage('team');

	return (
		<div className={classes}>
			<Breadcrumb background={breadcrumb.background} title={breadcrumb.title} />
			<h1>Team</h1>
		</div>
	);
};

const Team = styled(TeamUnStyled)``;

export default Team;
