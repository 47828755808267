import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import classNames from 'classnames';

import FontAwesome from '@component/shared/font-awesome';

import useBreakpoint from '@util/hooks/useBreakpoint';
import useTheme from '@util/hooks/useTheme';

import component from '@config/component';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger);

const FloatingHeaderUnStyled = ({ className }) => {
	const cls = classNames(className, 'floating-header');

	const { showLoader, isMenuOpen, toggleMenu } = useTheme();
	const { is } = useBreakpoint();

	const location = useLocation();
	const navigate = useNavigate();

	useGSAP(() => {
		const menu = document.querySelector('.floating-header');

		ScrollTrigger.create({
			trigger: document.body,
			start: "120px top",
			end: "bottom top",
			scrub: true,
			delay: -1,
			onUpdate: (self) => {
				if (self.direction === -1 && self.progress > 0.11) {
					gsap.to(menu, {
						duration: .5,
						top: 0,
						opacity: 1,
						ease: 'power1.in',
					});
				} else {
					gsap.to(menu,{
						top: -97,
						opacity: 0,
						ease: 'power1.out',
					});
				}
			}
		});
	});

	return (
		<div className={cls}>
			<Container>
				<Navbar expand="lg">
					<Navbar.Brand
						onClick={() => {
							if (location.pathname === '/') return;
							showLoader({ navigate: () => navigate('/') });
						}}
					>
						<img src={'/assets/images/logo/logo-2.svg'} alt={'Domicile Logo'} width={160} />
					</Navbar.Brand>

					{is(['xxs', 'xs', 'sm', 'md']) && (
						<span className={'d-flex align-items-center'}>
							<button className={'menu'} onClick={() => toggleMenu()}>
								{isMenuOpen ? <FontAwesome icon={'times'} /> : <FontAwesome icon={'bars'} />}
							</button>
						</span>
					)}
				</Navbar>
			</Container>
		</div>
	);
};

const FloatingHeader = styled(FloatingHeaderUnStyled)`
	position: fixed;
	height: 97px;
	width: 100%;
	z-index: 990;
	background: ${component.color.primary};
	top: -97px;

	.container {
		height: 97px;

		.nav {
			&bar {
				padding: 0 !important;
				display: flex;
				justify-content: space-between;
				height: 100%;

				&-brand {
					cursor: pointer;
				}
			}
		}
	}

	.menu {
		background: none;
		border: none;
		margin: 2px 0 0 15px;
		padding: 0;

		& i {
			color: ${component.color.white};
			font-size: 24px;
		}
	}
`;

export default FloatingHeader;
