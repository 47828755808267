import styled from 'styled-components';

import FontAwesome from '@component/shared/font-awesome';

import component from '@config/component';

import icon from '@asset/img/logo/icon.png';
import useTheme from '@util/hooks/useTheme';

const RightBarUnStyled = ({ className }) => {
	const { isMenuOpen, toggleMenu } = useTheme();
	return (
		<>
			<div className={className}>
				<div className="main">
					<div className="menu" onClick={() => toggleMenu()}>
						{isMenuOpen ? <FontAwesome icon={'times'} /> : <FontAwesome icon={'bars'} />}
					</div>
					<p className="domain-name">dream.build.live</p>
					<div className="icon">
						<img src={icon} alt={'Domicile Icon'} />
					</div>
				</div>
			</div>
		</>
	);
};

const RightBar = styled(RightBarUnStyled)`
	position: fixed;
	width: 114px;
	height: 100%;
	right: 0;
	top: 0;
	z-index: 999;

	.main {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 100vh;
		background: ${component.color.primary};
		overflow: hidden;

		& .menu {
			height: 97px;
			width: 114px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			& i {
				color: ${component.color.white};
				font-size: 24px;
			}
		}

		.domain-name {
			writing-mode: tb-rl;
			transform: rotate(-180deg);
			font-family: ${component.font.family.reckless};
			font-weight: ${component.font.weight['400']} !important;
			font-size: 22px;
			color: ${component.color.white};
			letter-spacing: 3px;
		}

		& .icon {
			height: 97px;
			width: 114px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
`;

export default RightBar;
