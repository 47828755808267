import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import Breadcrumb from '@component/layout/main/breadcrumb';

import PreFooter from '@component/shared/prefooter';
import component from '@config/component';

import useData from '@data/useData';

const TermsUnStyled = ({ className }) => {
	const { getPage } = useData();
	const { breadcrumb } = getPage('terms');

	return (
		<div className={className}>
			<Breadcrumb title={breadcrumb.title} background={breadcrumb.background} />
			<Container className={'py-5'}>
				<h2 className={'mb-4'}>Effective Date: [Insert Date]</h2>
				<p>
					Welcome to www.domicile.com.bd (the "Site"). These Terms and Conditions ("Terms") govern your use of
					the Site operated by Domicile ("we," "our," or "us"). By accessing or using the Site, you agree to
					be bound by these Terms. If you do not agree with any part of these Terms, you must not use the
					Site.
				</p>

				<h2 className={'mt-5 mb-4'}>1. Use of the Site</h2>
				<p>
					You agree to use the Site only for lawful purposes and in a way that does not infringe the rights
					of, restrict, or inhibit anyone else's use and enjoyment of the Site. Prohibited behavior includes
					harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive
					content, or disrupting the normal flow of dialogue within the Site.
				</p>

				<h2 className={'mt-5 mb-4'}>2. Intellectual Property</h2>
				<p>
					All content on the Site, including but not limited to text, graphics, logos, images, and software,
					is the property of Domicile or its content suppliers and is protected by international copyright
					laws. You may not reproduce, distribute, modify, create derivative works of, publicly display,
					publicly perform, republish, download, store, or transmit any of the material on our Site, except as
					follows:
				</p>

				<ul>
					<li>
						Your computer may temporarily store copies of such materials in RAM incidental to your accessing
						and viewing those materials.
					</li>
					<li>
						You may store files that are automatically cached by your Web browser for display enhancement
						purposes.
					</li>
					<li>
						You may print or download one copy of a reasonable number of pages of the Site for your own
						personal, non-commercial use and not for further reproduction, publication, or distribution.
					</li>
				</ul>

				<h2 className={'mt-5 mb-4'}>3. User Contributions</h2>
				<p>
					The Site may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin
					boards, and other interactive features (collectively, "Interactive Services") that allow users to
					post, submit, publish, display, or transmit to other users or other persons (hereinafter, "post")
					content or materials (collectively, "User Contributions") on or through the Site. All User
					Contributions must comply with the Content Standards set out in these Terms.
				</p>
				<p>
					Any User Contribution you post to the Site will be considered non-confidential and non-proprietary.
					By providing any User Contribution on the Site, you grant us and our affiliates and service
					providers, and each of their and our respective licensees, successors, and assigns the right to use,
					reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such
					material for any purpose.
				</p>

				<h2 className={'mt-5 mb-4'}>4. Prohibited Uses</h2>
				<p>
					You may use the Site only for lawful purposes and in accordance with these Terms. You agree not to
					use the Site:
				</p>

				<ul>
					<li>
						In any way that violates any applicable federal, state, local, or international law or
						regulation.
					</li>
					<li>
						For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by
						exposing them to inappropriate content, asking for personally identifiable information, or
						otherwise.
					</li>
					<li>
						To transmit, or procure the sending of, any advertising or promotional material, including any
						"junk mail," "chain letter," "spam," or any other similar solicitation.
					</li>
					<li>
						To impersonate or attempt to impersonate Domicile, a Domicile employee, another user, or any
						other person or entity (including, without limitation, by using email addresses or screen names
						associated with any of the foregoing).
					</li>
					<li>
						To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site,
						or which, as determined by us, may harm Domicile or users of the Site, or expose them to
						liability.
					</li>
				</ul>

				<h2 className={'mt-5 mb-4'}>5. Disclaimer of Warranties</h2>
				<p>
					The Site is provided on an "as is" and "as available" basis, without any warranties of any kind,
					either express or implied, including, but not limited to, implied warranties of merchantability,
					fitness for a particular purpose, or non-infringement. Domicile does not warrant that the Site will
					be uninterrupted, secure, or error-free, that defects will be corrected, or that the Site or the
					server that makes it available are free of viruses or other harmful components.
				</p>

				<h2 className={'mt-5 mb-4'}>6. Limitation of Liability</h2>
				<p>
					To the fullest extent permitted by applicable law, in no event will Domicile, its affiliates, or
					their licensors, service providers, employees, agents, officers, or directors be liable for damages
					of any kind, under any legal theory, arising out of or in connection with your use, or inability to
					use, the Site, any websites linked to it, any content on the Site or such other websites, including
					any direct, indirect, special, incidental, consequential, or punitive damages, including but not
					limited to, personal injury, pain and suffering, emotional distress, loss of revenue, loss of
					profits, loss of business or anticipated savings, loss of use, loss of goodwill, loss of data, and
					whether caused by tort (including negligence), breach of contract, or otherwise, even if
					foreseeable.
				</p>

				<h2 className={'mt-5 mb-4'}>7. Changes to These Terms</h2>
				<p>
					We may revise and update these Terms from time to time in our sole discretion. All changes are
					effective immediately when we post them and apply to all access to and use of the Site thereafter.
					Your continued use of the Site following the posting of revised Terms means that you accept and
					agree to the changes.
				</p>

				<h2 className={'mt-5 mb-4'}>8. Governing Law</h2>
				<p>
					All matters relating to the Site and these Terms and any dispute or claim arising therefrom or
					related thereto (in each case, including non-contractual disputes or claims), shall be governed by
					and construed in accordance with the internal laws of Bangladesh without giving effect to any choice
					or conflict of law provision or rule (whether of Bangladesh or any other jurisdiction).
				</p>

				<h2 className={'mt-5 mb-4'}>9. Contact Us</h2>
				<p>
					If you have any questions about these Terms, please contact us at: hello@domicile.com.bd. You can
					also reach us by mail at: Domicile [Insert Address] Dhaka, Bangladesh
				</p>
			</Container>

			<PreFooter
				bg={component.image('e13c4f127765023.6148cad2612a7_txd7ov', [
					'f_webp',
					'q_auto',
					'w_1920',
					'h_600',
					'c_fill'
				])}
				content={'Crafting Your Dream Home\nBegin Your Journey with Domicile'}
			/>
		</div>
	);
};

const Terms = styled(TermsUnStyled)`
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${component.font.family.reckless};
	}

	h1 {
		font-size: 42px;
	}

	& h2 {
		font-size: 36px;
	}

	h3 {
		font-size: 30px;
	}

	h4 {
		font-size: 24px;
	}

	h5 {
		font-size: 18px;
	}

	h6 {
		font-size: 12px;
	}

	p {
		font-family: ${component.font.family.poppins};
		font-weight: ${component.font.weight[300]};
		line-height: 26px;
		font-size: 14px;
		text-align: justify;
	}

	ul {
		li {
			font-family: ${component.font.family.poppins};
			font-weight: ${component.font.weight[300]};
			line-height: 26px;
			font-size: 14px;
			text-align: justify;
		}
	}

	img {
		object-fit: cover;
	}

	.section {
		margin-bottom: 40px;

		.title {
			font-family: ${component.font.family.reckless};
			font-size: 48px;
			margin-bottom: 20px;
		}

		.content {
			text-align: justify-all;

			p {
				text-align: justify;
			}
		}
	}
`;

export default Terms;
