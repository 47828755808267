import { createBrowserRouter } from 'react-router-dom';

import MainLayout from '@component/layout/main';

import Projects from '@page/projects';
import Project from '@page/project';
import Privacy from '@page/privacy';
import Contact from '@page/contact';
import Career from '@page/career';
import About from '@page/about';
import Terms from '@page/terms';
import Query from '@page/query';
import Home from '@page/home';
import Team from '@page/team';

const router = createBrowserRouter([
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{
				path: '/',
				element: <Home />
			},
			{
				path: '/about-us',
				element: <About />
			},
			{
				path: '/projects',
				element: <Projects />
			},
			{
				path: '/project/:slug',
				element: <Project />
			},
			{
				path: '/career',
				element: <Career />
			},
			{
				path: '/team',
				element: <Team />
			},
			{
				path: '/query/:as?',
				element: <Query />
			},
			{
				path: '/contact',
				element: <Contact />
			},
			{
				path: '/terms-conditions',
				element: <Terms />
			},
			{
				path: '/privacy-policy',
				element: <Privacy />
			}
		]
	}
]);

export default router;
