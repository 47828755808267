import styled from 'styled-components';
import component from '@config/component';
import hexToRgba from 'hex-to-rgba';
import FontAwesome from '@component/shared/font-awesome';
import Button from '@component/shared/button';
import useTheme from '@util/hooks/useTheme';
import { useNavigate } from 'react-router-dom';

const ProjectTwoUnStyled = ({ project, className }) => {
	const {showLoader} = useTheme()
	const navigate = useNavigate();
	return (
		<div
			className={className}
			onClick={() => {
				showLoader({navigate: () => navigate(`/project/${project.slug}`)});
			}}
		>
			<div className="bg">
				<img
					src={component.image(project.images.featured, ['f_webp', 'q_auto', 'w_600', 'h_600', 'c_fill'])}
					alt={project.name}
				/>
				<div className="overlay">
					<div className="content-top">
						{
							project.images.hasOwnProperty('logo') &&
							<img src={component.image(project.images.logo, ['f_webp', 'q_auto', 'w_200'])} alt={`${project.name} Logo`}/>
						}
					</div>
					<div className="content-bottom">
						<div className="span">
							<h3>{project.name}</h3>
							<p>
								<FontAwesome icon={'location-dot'} className={'me-2'} />
								{project.address.short}
							</p>
						</div>
						<Button href={`/project/${project.slug}`} icon={{ icon: 'eye', position: 'start' }} />
					</div>
				</div>
			</div>
		</div>
	);
};

const ProjectTwo = styled(ProjectTwoUnStyled)`
	cursor: pointer;

	.bg {
		width: 100%;
		height: 350px;
		position: relative;
		border-radius: 6px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition-duration: 400ms;
		}

		.overlay {
			width: 100%;
			height: 100%;
			padding: 20px;
			position: absolute;
			background: linear-gradient(to bottom, transparent, ${hexToRgba(component.color.primary, 1)});
			bottom: 0;
			transition-duration: 400ms;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.content {
				&-top {
					transition-duration: 400ms;
					/*display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: -60px;*/
					visibility: hidden;
					opacity: 0;
					
					img {
						width: 120px;
						height: 30px;
						object-fit: contain;
						object-position: left;
                        -webkit-filter: drop-shadow(0 0 0 #ffffff);
                        filter: drop-shadow(1px 1px 0 #ffffff);
					}

					/*.tag {
						font-family: ${component.font.family.poppins};
						font-weight: ${component.font.weight[300]};
						font-size: 14px;
						color: ${component.color.white};
						background: ${component.color.primary};
						padding: 5px 10px;
						border-radius: 5px;
						text-transform: uppercase;
					}*/
				}

				&-bottom {
					transition-duration: 400ms;
					display: flex;
					justify-content: space-between;
					align-items: center;

					h3 {
						font-family: ${component.font.family.reckless};
						font-size: 24px;
						color: ${component.color.white};
					}

					p {
						font-family: ${component.font.family.poppins};
						font-weight: ${component.font.weight[300]};
						font-size: 14px;
						color: ${component.color.white};
						margin-bottom: 0;
					}

					button {
						padding: 7px 10px;
						margin-right: -60px;
						visibility: hidden;
						opacity: 0;
					}
				}
			}
		}
	}

	&:hover {
		.bg {
			img {
				scale: 1.1;
			}
		}

		.overlay {
			.content {
				&-bottom {
					button {
						visibility: visible;
						opacity: 1;
						margin-right: 0;
					}
				}
				&-top {
					visibility: visible;
					opacity: 1;
					margin-top: 0;
				}
			}
		}
	}
`;

export default ProjectTwo;
