import styled from 'styled-components';
import classNames from 'classnames';
import hexToRgba from 'hex-to-rgba';

import FontAwesome from '@component/shared/font-awesome';

import component from '@config/component';
import useTheme from '@util/hooks/useTheme';

const JobUnStyles = ({ className, job, onClick }) => {
	const classes = classNames(className, 'job');

	const { openJob } = useTheme();

	return (
		<div className={classes} onClick={() => openJob({ slug: job.slug })}>
			<h4 className={'title'}>{job.title}</h4>
			<div className={'point'}>
				<FontAwesome type={'light'} icon={'location-dot'} />
				<p>{job.location}</p>
			</div>
			<div className={'point'}>
				<FontAwesome type={'light'} icon={'clock-desk'} />
				<p>{job.nature}</p>
			</div>
			<div className={'point'}>
				<FontAwesome type={'light'} icon={'sack-dollar'} />
				<p>{job.salary}</p>
			</div>
			<div className={'point'}>
				<FontAwesome type={'light'} icon={'user'} />
				<p>{job.vacancy} (Vacancy)</p>
			</div>
			<div className={'point'}>
				<FontAwesome type={'light'} icon={'flask-round-potion'} />
				<p>{job.experience} Experience</p>
			</div>
		</div>
	);
};

const Job = styled(JobUnStyles)`
	border: 1px solid ${hexToRgba(component.color.primary, 0.2)};
	border-radius: 6px;
	padding: 15px;
	cursor: pointer;
	transition-duration: 500ms;

	& .title {
		font-family: ${component.font.family.reckless};
		font-weight: ${component.font.weight[600]};
		color: ${component.color.primary};
		font-size: 24px;
		margin-bottom: 15px;

		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
	}

	& .point {
		display: flex;
		align-items: center;
		margin-bottom: 6px;

		& i {
			height: 20px;
			width: 15px;
			margin-right: 8px;
		}

		& p {
			font-family: ${component.font.family.poppins};
			font-weight: ${component.font.weight[300]};
			font-size: 14px;
			margin-bottom: 0;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 1;
		}
	}

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
		scale: 1.03;
	}
`;

export default Job;
