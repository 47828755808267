import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import classNames from 'classnames';

import Breadcrumb from '@component/layout/main/breadcrumb';

import useData from '@data/useData';
import component from '@config/component';

import LandownerForm from '@page/query/components/landowner-form';
import BuyerForm from '@page/query/components/buyer-form';
import QuerySelector from '@page/query/components/query-selector';

const QueryUnStyled = ({ className }) => {
	const classes = classNames(className, 'query');

	const { as } = useParams();

	const { getPage } = useData();
	const { breadcrumb } = getPage('query');

	return (
		<div className={classes}>
			<Breadcrumb background={breadcrumb.background} title={breadcrumb.title} />

			{!as && <QuerySelector />}
			{as === 'landowner' && <LandownerForm />}
			{as === 'buyer' && <BuyerForm />}
		</div>
	);
};

const Query = styled(QueryUnStyled)`
	.query {
		&-title {
			font-family: ${component.font.family.reckless};
			font-size: 42px;
		}

		&-content {
			min-height: 590px !important;
		}

		&-selector {
			overflow: hidden;
		}
	}

	.forms {
		display: none;
		visibility: hidden;
	}
`;

export default Query;
