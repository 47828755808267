import styled from 'styled-components';
import classNames from 'classnames';

import component from '@config/component';

const SubTitleUnStyled = ({ className, text, api }) => {
	const classes = classNames(className, 'hero-subtitle');
	return <h4 className={classes}>{text}</h4>;
};

const SubTitle = styled(SubTitleUnStyled)`
	font-family: ${component.font.family.poppins};
	font-weight: ${component.font.weight[200]};
	font-size: 18px;
	line-height: 28px;
	margin-bottom: 25px;
	color: ${component.color.white};
	overflow: hidden;
	display: -webkit-box;
	line-clamp: 2;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;

	/*${() => component.breakpoint.md.mixin('text-align: center;')}
	${() => component.breakpoint.sm.mixin('text-align: center; font-size: 14px;')}
    ${() => component.breakpoint.xs.mixin('text-align: center; font-size: 14px; line-height: 22px;')}
    ${() => component.breakpoint.xxs.mixin('text-align: center; font-size: 14px; line-height: 22px;')}*/
	
    ${() => component.breakpoint.md.mixin('text-align: center;')}
    ${() => component.breakpoint.sm.mixin('text-align: center; font-size: 14px;')}
    ${() => component.breakpoint.xs.mixin('display: none;')}
    ${() => component.breakpoint.xxs.mixin('display: none;')}
`;

export default SubTitle;
