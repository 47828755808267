import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Fragment } from 'react';

import CallToAction from '@component/shared/call-to-action';
import SplitSection from '@component/shared/split-section';

import FeaturedProjects from '@page/home/components/featuredProjects';
import Hero from '@page/home/components/hero';

import component from '@config/component';
import useData from '@data/useData';
import useTheme from '@util/hooks/useTheme';
import About from '@page/home/components/about';

const HomeUnStyled = ({ className }) => {
	const { showLoader } = useTheme();
	const { getProjects } = useData();

	const navigate = useNavigate();

	return (
		<div className={className}>
			<Hero />
			<About />
			<FeaturedProjects />
			<SplitSection
				title={'Pathways to Excellence'}
				text={
					'At Domicile Design and Builders, we’ve built more than just structures—we’ve crafted lasting legacies. With over 18 years of excellence, our portfolio includes 30+ prestigious projects across the most sought-after locations in Dhaka, including Uttara, Banani, Gulshan, Dhanmondi, and Mirpur. Serving over 1000 satisfied clients, we blend trust, innovation, and sustainability to create homes and communities that stand the test of time and elevate the way people live.'
				}
			/>
			<CallToAction
				title={"Ready to Transform Your Space? Let's Get Started with Domicile!"}
				button={{ children: 'Call Now', href: '/contact' }}
			/>
			<GoogleMap
				mapContainerStyle={{
					width: '100%',
					height: '600px'
				}}
				center={{
					lat: 23.816424,
					lng: 90.366123
				}}
				zoom={12}
			>
				{getProjects().map((project, i) => (
					<Fragment key={i}>
						{project?.location?.lat && project?.location?.lng && (
							<MarkerF
								position={{ lat: project.location.lat, lng: project.location.lng }}
								draggable={false}
								icon={'/assets/images/pin.png'}
								onClick={() => showLoader({ navigate: () => navigate(`/project/${project.slug}`) })}
							/>
						)}
					</Fragment>
				))}
			</GoogleMap>

			{/*<Categories />*/}
		</div>
	);
};

const Home = styled(HomeUnStyled)`
	scroll-behavior: smooth;

	.section {
		width: calc(100vw - 114px) !important;
		overflow: hidden !important;

		${() => component.breakpoint.md.mixin('width: 100% !important;')}
		${() => component.breakpoint.sm.mixin('width: 100% !important;')}
        ${() => component.breakpoint.xs.mixin('width: 100% !important;')}
        ${() => component.breakpoint.xxs.mixin('width: 100% !important;')}
	}
`;

export default Home;
