import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import useData from '@data/useData';
import Select from 'react-select';
import { useEffect, useState } from 'react';

import component from '@config/component';
import select from '@config/select';

import Breadcrumb from '@component/layout/main/breadcrumb';

import CallToAction from '@component/shared/call-to-action';
import PreFooter from '@component/shared/prefooter';
import Highlight from '@component/shared/highlight';
import Project from '@component/shared/project';
import { useLocation, useNavigate } from 'react-router-dom';

const ProjectsUnStyled = ({ className }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(location.search);

	const [name, setName] = useState('');
	const [category, setCategory] = useState({});
	const [loc, setLoc] = useState({});
	const [status, setStatus] = useState({});

	const {
		getPage,
		getProjects,
		getProjectCategories,
		getProjectStatuses,
		getProjectCategory,
		getProjectStatus,
		getProjectLocations
	} = useData();
	const { breadcrumb } = getPage('projects');

	useEffect(() => {
		if (params.has('category')) {
			const cat = getProjectCategory(params.get('category'));
			setCategory({
				label: cat.name,
				value: cat.slug
			});
		} else {
			setCategory(null);
		}

		if (params.has('status')) {
			const sta = getProjectStatus(params.get('status'));
			setStatus({
				label: sta.name,
				value: sta.slug
			});
		} else {
			setStatus(null);
		}

		if (params.has('location')) {
			const lo = params.get('location');
			setLoc({
				label: lo,
				value: lo
			});
		} else {
			setLoc(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]);

	return (
		<div className={className}>
			<Breadcrumb title={breadcrumb.title} background={breadcrumb.background} />
			<Container className={'py-5 mb-5'}>
				<Row className={'gy-3'}>
					<Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
						<div className="form-control">
							<label>Name</label>
							<input placeholder={'Project Name'} onChange={(e) => setName(e.target.value)} />
						</div>
					</Col>
					<Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
						<div className="form-control">
							<label>Category</label>
							<Select
								styles={select}
								options={getProjectCategories().map((item) => ({
									label: item.name,
									value: item.slug
								}))}
								value={category}
								isClearable={true}
								placeholder={'Select Category'}
								onChange={(e) => {
									if (e === null) {
										if (params.has('category')) params.delete('category');

										location.search = params.toString();
										navigate(location);
									} else {
										if (params.has('category')) params.set('category', e.value);
										else params.append('category', e.value);

										location.search = params.toString();
										navigate(location);
									}
								}}
							/>
						</div>
					</Col>
					<Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
						<div className="form-control">
							<label>Location</label>
							<Select
								styles={select}
								options={getProjectLocations().map((item) => ({
									label: item,
									value: item
								}))}
								value={loc}
								isClearable={true}
								placeholder={'Select Location'}
								onChange={(e) => {
									if (e === null) {
										if (params.has('location')) params.delete('location');

										location.search = params.toString();
										navigate(location);
									} else {
										if (params.has('location')) params.set('location', e.value);
										else params.append('location', e.value);

										location.search = params.toString();
										navigate(location);
									}
								}}
							/>
						</div>
					</Col>
					<Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
						<div className="form-control">
							<label>Status</label>
							<Select
								styles={select}
								options={getProjectStatuses().map((item) => ({ label: item.name, value: item.slug }))}
								isClearable={true}
								placeholder={'Select Status'}
								onChange={(e) => {
									if (e === null) {
										if (params.has('status')) params.delete('status');

										location.search = params.toString();
										navigate(location);
									} else {
										if (params.has('status')) params.set('status', e.value);
										else params.append('status', e.value);

										location.search = params.toString();
										navigate(location);
									}
								}}
							/>
						</div>
					</Col>
				</Row>
				<Row className={'gy-4 mt-1'}>
					{getProjects({ name, category: category?.value, status: status?.value, location: loc?.value }).map(
						(project, i) => {
							return (
								<Col xxl={4} xl={4} lg={6} md={6} sm={12} xs={12} key={i}>
									<Project styleNo={2} project={project} />
								</Col>
							);
						}
					)}
				</Row>
			</Container>

			<CallToAction
				title={"Ready to Transform Your Space? Let's Get Started with Domicile!"}
				button={{ children: 'Call Now', href: '/contact' }}
			/>

			<Container className={'py-5'}>
				<div className="section">
					<div className="title">Our Expertise</div>
					<div className="content">
						<p>
							With over 30 years of experience in the industry, we have successfully completed more than
							300 projects, consistently delivering excellence and innovation. Our team of skilled
							professionals is dedicated to creating spaces that are not only aesthetically pleasing but
							also functional and sustainable. We specialize in residential, commercial, and hospitality
							projects, ensuring that each development is meticulously planned and executed to meet the
							highest standards of quality and craftsmanship.
						</p>
						<p>
							Our commitment to sustainability and innovation sets us apart in the real estate industry.
							We incorporate eco-friendly materials and energy-efficient systems into our projects,
							promoting a greener and more sustainable future. Our mixed-use developments seamlessly blend
							residential, commercial, and recreational spaces, creating vibrant communities where people
							can live, work, and play. Whether it's designing luxurious villas, state-of-the-art office
							spaces, or unique boutique hotels, our expertise ensures that every project is a testament
							to our dedication to excellence and our passion for transforming spaces and enriching lives.
						</p>
					</div>
				</div>

				<Row className={'g-5 mb-3'}>
					<Col xxl={4} xl={4} lg={4} md={4} sm={12}>
						<Highlight title={'15+'} subtitle={'Years of experience'} />
					</Col>
					<Col xxl={4} xl={4} lg={4} md={4} sm={12}>
						<Highlight title={'70+'} subtitle={'Projects delivered'} />
					</Col>
					<Col xxl={4} xl={4} lg={4} md={4} sm={12}>
						<Highlight title={'10+'} subtitle={'Projects per year'} />
					</Col>
				</Row>
			</Container>

			<PreFooter
				bg={component.image('967bae163388161.63e4e34a3a50a_aato77', [
					'f_webp',
					'q_auto',
					'w_1920',
					'h_600',
					'c_fill'
				])}
				content={'Elevate Your Living Experience\nDiscover the Art of Home with Domicile'}
			/>
		</div>
	);
};

const Projects = styled(ProjectsUnStyled)`
	.form {
		&-title {
			font-family: ${component.font.family.reckless};
			font-size: 42px;
			margin-bottom: 30px;
		}

		&-control {
			border: none !important;
			padding: 0;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			label {
				font-family: ${component.font.family.reckless};
				font-size: 24px;
				margin-bottom: 10px;
			}

			input {
				padding: 15px 0;
				border: 0;
				border-bottom: 1px solid ${hexToRgba(component.color.black, 0.4)};
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				color: ${component.color.black};

				&::placeholder {
					font-family: ${component.font.family.poppins};
					font-weight: ${component.font.weight[300]};
					color: ${hexToRgba(component.color.black, 0.4)};
				}

				&:hover,
				&:focus,
				&:active {
					outline: none;
					box-shadow: none;
				}
			}
		}
	}

	.section {
		margin-bottom: 40px;

		.title {
			font-family: ${component.font.family.reckless};
			font-size: 48px;
			margin-bottom: 20px;
		}

		.content {
			text-align: justify-all;

			p {
				font-family: ${component.font.family.poppins};
				font-weight: ${component.font.weight[300]};
				font-size: 14px;
				text-align: justify;
			}
		}
	}
`;

export default Projects;
