import styled from 'styled-components';

import component from '@config/component';
import Button from '@component/shared/button';

import { gsap } from 'gsap';
import { SplitText } from 'gsap-trial/SplitText';
import { useGSAP } from '@gsap/react';
import { Container } from 'react-bootstrap';

gsap.registerPlugin(SplitText);

const CallToActionUnStyled = ({ className, title, button }) => {
	useGSAP(() => {
		const headingTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: '.cta-section',
				start: 'top 80%',
				toggleActions: 'play pause resume reset'
			}
		});
		const heading = new SplitText('.cta-title', { type: 'words,chars' });

		const buttonTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: '.cta-section',
				start: 'top 80%',
				toggleActions: 'play pause resume reset'
			}
		});
		const button = document.querySelector('.cta-button');

		headingTimeline
			.from(heading.words, {
				duration: 0.5,
				opacity: 0,
				y: 80,
				delay: 0.6,
				stagger: 0.08,
				ease: 'quart'
			})
			.to(heading.chars, {
				opacity: 1,
				y: 0
			});

		buttonTimeline
			.from(button, {
				duration: 0.5,
				opacity: 0,
				x: 100,
				delay: 0.6
			})
			.to(button, {
				opacity: 1,
				x: 0
			});
	});

	return (
		<div className={`${className} cta-section`}>
			<Container>
				<div className="overlay">
					<h2 className={'cta-title'}>{title}</h2>
					<Button {...button} className={`cta-button ${button?.className || ''}`} />
				</div>
			</Container>
		</div>
	);
};

const Index = styled(CallToActionUnStyled)`
	width: 100%;
	height: 600px;
	overflow: hidden;
	position: relative;
	background-color: ${component.color.primary};
	background-image: url('/assets/images/pattern-1.svg');
	background-size: 100%;

	${() => component.breakpoint.md.mixin('background-size: 150%;')}
	${() => component.breakpoint.sm.mixin('background-size: 150%;')}
	${() => component.breakpoint.xs.mixin('background-size: 200%;')}
    ${() => component.breakpoint.xxs.mixin('background-size: 250%;')}
	
	.container {
		height: 100%;
	}

	.overlay {
		width: 100%;
		height: 100%;
		padding: 70px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		h2 {
			font-family: ${component.font.family.reckless};
			font-weight: ${component.font.weight[800]};
			font-size: 48px;
			color: ${component.color.white};
			width: 40vw;

			${() => component.breakpoint.md.mixin('width: 65vw;')}
			${() => component.breakpoint.sm.mixin('width: 75vw; font-size: 42px;')}
			${() => component.breakpoint.xs.mixin('width: 100%; font-size: 36px;')}
			${() => component.breakpoint.xxs.mixin('width: 100%; font-size: 32px;')}
		}

		button {
			width: fit-content;
			align-self: end;
		}

		${() => component.breakpoint.sm.mixin('padding: 70px 100px;')}
		${() => component.breakpoint.xs.mixin('padding: 70px 70px;')}
        ${() => component.breakpoint.xxs.mixin('padding: 70px 30px;')}
	}
`;

export default Index;
