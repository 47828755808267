import styled from 'styled-components';
import component from '@config/component';

const HighlightUnStyled = ({ className, title, subtitle }) => {
	return (
		<div className={className}>
			<h2>{title}</h2>
			<p>{subtitle}</p>
		</div>
	);
};

const Highlight = styled(HighlightUnStyled)`
	background: ${component.color.primary};
	padding: 40px;

	h2,
	p {
		color: ${component.color.white};
	}

	& h2 {
		font-family: ${component.font.family.reckless};
		font-size: 60px !important;
		margin-bottom: 25px;
	}

	& p {
		font-family: ${component.font.family.reckless};
		font-size: 22px !important;
		margin-bottom: 0;
	}
`;

export default Highlight;
